import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormControl, FormLabel, Rating } from "@mui/material";

import { commonFunction } from "../utils/commonFunction";

const FFRatingsField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || 0
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);
  // get and check all validation
  const validate = async (value) => {
    setInputValue(parseInt(value));
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
  };

  return (
    <div className="form_field_outer_box">
      <FormControl>
        <FormLabel
          required={component.validate.required || component.validate.mandatory}
          sx={{ color: "var(--mui-label-shrink-color)", fontSize: "14px" }}
        >
          {component.label}
        </FormLabel>
        <Rating
          id={component.id}
          name="simple-controlled"
          value={parseInt(inputValue)}
          max={parseInt(component.max_ratings)}
          onChange={(e) => {
            validate(parseInt(e.target.value));
          }}
        />
      </FormControl>
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default FFRatingsField;
