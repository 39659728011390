import { useState, useLayoutEffect, useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import "react-phone-input-2/lib/material.css";
import { commonFunction } from "../utils/commonFunction";
import InfoIcon from "@mui/icons-material/Info"; // You can use Material UI's Info Icon

const FFPhone = ({ component, currentConfigKey, namespace }) => {
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  const [phoneInput, setPhoneInput] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || {
      dial_code: component.default_country_code || "",
      phone: ""
    }
  );
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) {
      let value = commonFunction.getKeyValueFromForm(namespace, currentConfigKey);
      if (value !== undefined) {
        setPhoneInput(value);
      }
    } else {
      setDefValFlag(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    if (showTooltip) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showTooltip]);

  const validate = async (phone, data) => {
    const number = phone.replace(data.dialCode, "");
    const phData = { dial_code: data.dialCode, phone: number };
    await commonFunction.callChackFiledFunction(namespace, phData, currentConfigKey, component);
  };

  return (
    <div className="form_field_outer_box" style={{ position: "relative" }}>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <PhoneInput
          inputStyle={{
            display: "flex",
            width: "100%",
            height: "40px",
            border: errors.length ? "1px solid red" : ""
          }}
          disabled={component.disabled}
          value={phoneInput?.dial_code || ""}
          specialLabel={component?.label}
          containerClass="custom-phone-input"
          onChange={(phone, data) => validate(phone, data)}
        />
        {component?.help_text && phoneInput?.phone === "" && (
          <InfoIcon
            style={{ position: "absolute", right: "25px", fill: "#667085" }}
            onClick={() => setShowTooltip(!showTooltip)}
          />
        )}
      </div>

      {showTooltip && (
        <div
          ref={tooltipRef}
          style={{
            position: "absolute",
            top: "40px",
            right: "0",
            backgroundColor: "#ffffff",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            zIndex: 1,
            width: "200px",
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            wordBreak: "break-word"
          }}
          onClick={() => setShowTooltip(false)}
        >
          <p style={{ margin: 0 }}>{component?.help_text}</p>
        </div>
      )}

      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFPhone;
