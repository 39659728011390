import React from "react";
import { getApiCallLocalPath } from "../../../utils/apiCallFunction";
import RecordsetRenderer from "../../detailsviewlib/RecordsetRenderer";
import ActivityCardRender from "../../detailsviewlib/ActivityCardRender";
import TimelineRenderer from "../../timeline/TimelineRenderer";
import TableViewRenderer from "../../tableviewlib";
import TabViewRenderer from "../../tabviewlib/TabViewRenderer";
import file_save from "../../../assets/images/downloadfileicon.png";
import IconRender from "../../../components/IconRender";
import JobActionRenderer from "../../actionlib/jobactionlib/JobActionRenderer";
import LabelField from "../../../components/fields/LabelField";
import { formatTimestamp, stringToJSONChange } from "../../utils/commonFunction";
import { useImagePreviewContext } from "../../../applicationcontext/ImagePreviewContext";
import SectionRenderer from "../SectionRenderer";
import DropdownActionRenderer from "../../actionlib/dropdownactionlib/DropdownActionRenderer";
import { getDataFromDataObjectByKey } from "../../../utils/updateConfigFunction";
import FormComponent from "../../../components/appview/FormComponent";
import "./CardRenderer.css";
import MapPopup from "../../../components/Map/MapPopup";
import ButtonGroupField from "../../../components/fields/ButtonGroupField";
import ProgressBar from "../../widgetsLib/ProgressBar";
import ProgressBarRenderer from "../../progressbarlib/ProgressBarRenderer";
import NumberFormater from "../../components/NumberFormater";

const CardItems = ({
  colItem,
  dataConfig,
  layoutDataLineHeight,
  activityCardsConfig,
  detailsViewDataActivityCard,
  detailsViewDataTimeline,
  detailsViewDataActivitiesHistory,
  jobParamsConfig,
  detailsViewDataOrg,
  functionObject,
  detailsViewItemConfig
}) => {
  const { showPopupImagePreview } = useImagePreviewContext();

  let styleObject = {
    gridArea: `zino-${colItem?.i || colItem.id}`,
    padding: "0px 5px"
  };

  const handleNavigate = (urlData) => {
    if (urlData?.length > 0 && urlData[0] !== "-") {
      window.open(urlData?.join(","));
    }
  };

  //classnames coming from builder for that element
  let class_name = "";
  if (colItem.className) {
    if (typeof colItem.className === "object") {
      class_name = Object.values(colItem.className).join(" ");
    } else {
      class_name = colItem.className;
    }
  }

  let dataIs = [];
  if (colItem?.field_type === "lookup-sm") {
    getDataFromDataObjectByKey(dataConfig, dataIs, `${colItem?.data_key}.instance_id` || "");
  } else {
    getDataFromDataObjectByKey(dataConfig, dataIs, colItem?.data_key || "");
  }
  //for text dynamic color
  let colorIf = {};
  if (colItem?.colorif) {
    for (let index = 0; index < colItem.colorif.length; index++) {
      const elem = colItem.colorif[index];
      colorIf[elem.value.toLowerCase()] = elem.color;
    }
  }

  let bgColor = "";
  if (typeof dataIs[0] === "string") {
    bgColor = colorIf[dataIs[0].toLowerCase()];
  } else if (typeof dataIs[0] === "number") {
    bgColor = colorIf[dataIs[0]];
  }

  let showPrefix =
    dataIs?.length > 0 && dataIs[0] !== "-" && (colItem?.prefix_data || colItem?.postfix_data) ? true : false;

  if (["recordset", "recordset-list", "grid"].includes(colItem.field_type)) {
    return (
      <div key={colItem.id} style={styleObject}>
        <RecordsetRenderer itemConfig={colItem} detailsViewData={dataConfig} />
      </div>
    );
  } else if ((colItem.field_type === "lookup-sm" || colItem.parent_type === "lookup") && colItem.field_action) {
    colItem.field_action.data_id = jobParamsConfig?.sm_id;
    const newRecord = dataConfig[colItem?.id?.split(".")[0]];
    jobParamsConfig.instance_id = newRecord?.id;
    return (
      <div key={colItem.id} style={styleObject}>
        <JobActionRenderer itemConfig={colItem.field_action} jobParamsConfig={jobParamsConfig} />
      </div>
    );
  } else if (["selectboxes", "select-chips", "learning-tags"].includes(colItem.field_type)) {
    let newData = flattenArray(dataIs);
    return (
      <div className="card_text_data" style={styleObject}>
        {colItem?.icon && <IconRender iconValue={colItem?.icon} />}
        {newData?.length > 0 ? (
          <div className="chipDefaultContainer">
            {newData?.map((item, index) => {
              let bgColor = "";
              if (typeof item === "string") {
                bgColor = colorIf[item.toLowerCase()];
              } else if (typeof item === "number") {
                bgColor = colorIf[item];
              }
              return (
                <p
                  key={index}
                  style={{
                    ...styleObject,
                    ...colItem?.inline_style,
                    ...(bgColor && { background: bgColor })
                  }}
                  className={`chipDefaultStyle ${class_name}`}
                >
                  {item}
                </p>
              );
            })}
          </div>
        ) : (
          <div className="card_text_data">
            <span className="value">-</span>
          </div>
        )}
      </div>
    );
  } else if (["url"].includes(colItem.field_type)) {
    return (
      <div
        className={`card_text_data ${colItem?.show_chips ? "fieldChipStyle" : null} ${class_name}`}
        key={colItem?.id}
        style={{
          ...styleObject,
          ...colItem?.inline_style,
          ...(bgColor && (colItem?.show_chips ? { backgroundColor: bgColor } : { color: bgColor }))
        }}
        onClick={() => handleNavigate(dataIs)}
      >
        {colItem.icon && <IconRender iconValue={colItem.icon} />}
        <span className="value">{dataIs.join(", ") || "-"}</span>
      </div>
    );
  } else if (
    ["textfield", "text", "select", "string"].includes(colItem.data_type || colItem.field_type) ||
    ["learning-text", "radio", "checkbox", "toggle", "number-select"].includes(colItem.field_type)
  ) {
    return (
      <div
        className={`card_text_data ${colItem?.show_chips ? "fieldChipStyle" : null} ${class_name}`}
        key={colItem.id}
        style={{
          ...styleObject,
          display: "flex",
          alignItems: "center",
          ...colItem?.inline_style,
          ...(bgColor && (colItem?.show_chips ? { backgroundColor: bgColor } : { color: bgColor }))
        }}
      >
        {colItem.icon && <IconRender iconValue={colItem.icon} />}
        {colItem?.show_avatar && <span className="fieldAvatarStyle">{dataIs[0]?.slice(0, 1)}</span>}
        {showPrefix ? (
          <span className="value">
            {colItem?.prefix_data || ""} {dataIs.join(", ")} {colItem?.postfix_data || ""}
          </span>
        ) : (
          <span className="value">{dataIs.join(", ")}</span>
        )}
      </div>
    );
  } else if (["number"].includes(colItem.data_type) || ["percentage"].includes(colItem.field_type)) {
    return (
      <div
        className={`card_text_data ${colItem?.show_chips ? "fieldChipStyle" : null} ${class_name}`}
        key={colItem.id}
        style={{
          ...styleObject,
          ...colItem?.inline_style,
          display: "flex",
          alignItems: "center",
          ...(bgColor && (colItem?.show_chips ? { backgroundColor: bgColor } : { color: bgColor }))
        }}
      >
        {colItem.icon && <IconRender iconValue={colItem.icon} />}
        {colItem?.show_avatar && <span className="fieldAvatarStyle">{dataIs[0]?.slice(0, 1)}</span>}
        <span className="value">
          {showPrefix
            ? dataIs?.map((data, index) => (
                <NumberFormater
                  prefix={colItem?.prefix_data || ""}
                  postfix={colItem?.postfix_data || ""}
                  number={data}
                  type={colItem?.field_type === "percentage" ? "percentage" : colItem?.format}
                  curr_icon={colItem?.currency_code}
                  key={index}
                />
              ))
            : dataIs?.map((data, index) => (
                <NumberFormater
                  number={data}
                  type={colItem?.field_type === "percentage" ? "percentage" : colItem?.format}
                  curr_icon={colItem?.currency_code}
                  key={index}
                />
              ))}
        </span>
      </div>
    );
  } else if (["currency"].includes(colItem.field_type)) {
    function formatValue(currencyObject) {
      if (typeof currencyObject === "object") {
        const formatter = new Intl.NumberFormat(undefined, {
          style: "currency",
          currency: currencyObject?.currency
        });
        return formatter.format(currencyObject?.value);
      } else {
        return "-";
      }
    }
    return (
      <div
        className={`card_text_data ${colItem?.show_chips ? "fieldChipStyle" : null} ${class_name}`}
        key={colItem.id}
        style={{
          ...styleObject,
          ...colItem?.inline_style,
          display: "flex",
          alignItems: "center",
          ...(bgColor && (colItem?.show_chips ? { backgroundColor: bgColor } : { color: bgColor }))
        }}
      >
        {colItem.icon && <IconRender iconValue={colItem.icon} />}
        {colItem?.show_avatar && <span className="fieldAvatarStyle">{dataIs[0]?.slice(0, 1)}</span>}
        <span className="value">
          {showPrefix
            ? dataIs?.map((data) => `${colItem?.prefix_data || ""} ${formatValue(data)} ${colItem?.postfix_data || ""}`)
            : dataIs?.length > 0
            ? dataIs?.map((data) => formatValue(data))
            : "_"}
        </span>
      </div>
    );
  } else if (["textarea"].includes(colItem.data_type)) {
    return (
      <div
        className={"card_text_data " + class_name}
        key={colItem.id}
        style={{ ...styleObject, ...colItem?.inline_style }}
      >
        {colItem.icon && <IconRender iconValue={colItem.icon} />}
        {dataIs.map((dataString, dataIndex) => {
          dataString = dataString.replaceAll(/\n/g, "<br/>");
          return (
            <>
              {showPrefix && (colItem?.prefix_data || "")}
              <span key={dataIndex} className="value" dangerouslySetInnerHTML={{ __html: dataString }}></span>
              {showPrefix && (colItem?.postfix_data || "")}
            </>
          );
        })}
      </div>
    );
  } else if (["object"].includes(colItem.data_type)) {
    return (
      <div
        className={"card_text_data " + class_name}
        key={colItem.id}
        style={{ ...styleObject, display: "flex", alignItems: "center" }}
      >
        {colItem.icon && <IconRender iconValue={colItem.icon} />}
        <span className="value">{JSON.stringify(dataIs, null, 2)}</span>
      </div>
    );
  } else if (["geolocation"].includes(colItem.data_type)) {
    const lat = dataIs?.[0]?.[0];
    const lng = dataIs?.[0]?.[1];
    return (
      <div
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <MapPopup lat={lat} lng={lng} />
      </div>
    );
  } else if (colItem.field_type === "multi-date") {
    if (dataIs && dataIs !== "-" && dataIs.length !== 0) {
      let formattedDate = "";
      dataIs = dataIs.flat() || [];
      for (let index = 0; index < dataIs.length; index++) {
        const dateItem = dataIs[index];
        if (dateItem !== "-") {
          try {
            formattedDate += `${formatTimestamp(dateItem, "date", colItem?.format || "DD-MON-YYYY")} ${
              dataIs.length - 1 !== index ? ", " : ""
            }`;
          } catch (error) {
            formattedDate += "-";
          }
        } else {
          formattedDate += "-";
        }
      }
      return (
        <div
          className={"card_text_data " + class_name}
          key={colItem.id}
          style={{ ...styleObject, display: "flex", alignItems: "center", ...colItem?.inline_style }}
        >
          {colItem.icon && <IconRender iconValue={colItem.icon} />}
          <span>
            {colItem?.show_chips ? (
              <div className="chipDefaultContainer">
                {formattedDate?.split(",")?.map((item, index) => (
                  <p
                    key={index}
                    style={{ ...styleObject, ...colItem?.inline_style, ...(bgColor && { color: bgColor }) }}
                    className={`chipDefaultStyle ${class_name}`}
                  >
                    {item}
                  </p>
                ))}
              </div>
            ) : showPrefix ? (
              `${colItem?.prefix_data || ""} ${formattedDate} ${colItem?.postfix_data || ""}`
            ) : (
              formattedDate
            )}
          </span>
        </div>
      );
    } else {
      return (
        <div
          className={"card_text_data" + class_name}
          key={colItem.id}
          style={{ ...styleObject, display: "flex", alignItems: "center", ...colItem?.inline_style }}
        >
          <p>-</p>
        </div>
      );
    }
  } else if (["date", "day", "datetime", "time"].includes(colItem.data_type)) {
    if (dataIs && dataIs[0] !== "-") {
      let formattedDate = "";
      for (let index = 0; index < dataIs.length; index++) {
        const dateItem = dataIs[index];
        if (dateItem !== "-") {
          try {
            formattedDate += formatTimestamp(dateItem, colItem.field_type, colItem?.format || "DD-MM-YYYY");
          } catch (error) {
            formattedDate += "-";
          }
        } else {
          formattedDate += "-";
        }
      }
      return (
        <div
          className={"card_text_data " + class_name}
          key={colItem.id}
          style={{ ...styleObject, display: "flex", alignItems: "center", ...colItem?.inline_style }}
        >
          {colItem.icon && <IconRender iconValue={colItem.icon} />}
          <span>
            {showPrefix
              ? `${colItem?.prefix_data || ""} ${formattedDate} ${colItem?.postfix_data || ""}`
              : formattedDate}
          </span>
        </div>
      );
    } else {
      return (
        <div
          className={"card_text_data " + class_name}
          key={colItem.id}
          style={{ ...styleObject, display: "flex", alignItems: "center", ...colItem?.inline_style }}
        >
          {colItem.icon && <IconRender iconValue={colItem.icon} />}
          <p>{dataIs.join(",")}</p>
        </div>
      );
    }
  } else if (["image", "file"].includes(colItem.data_type) || ["image", "file"].includes(colItem.field_type)) {
    let imageFile = [];
    for (let s = 0; s < dataIs.length; s++) {
      const element = dataIs[s];
      if (typeof element === "object") {
        imageFile = [...imageFile, ...element];
      } else {
        imageFile.push(element);
      }
    }
    return (
      <div
        className={"overflow " + class_name}
        key={colItem.id}
        style={{
          ...styleObject,
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "8px"
        }}
      >
        {imageFile?.length !== 0 ? (
          imageFile?.map((image_src, image_index) => {
            if (typeof image_src === "object") {
              if (["image/png", "image/jpeg", "image/jpg"].includes(image_src.type)) {
                return (
                  <img
                    key={image_index}
                    style={{
                      height: "75px",
                      width: "auto",
                      objectFit: "contain",
                      border: "1px solid lightgray",
                      borderRadius: "4px"
                    }}
                    src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`}
                    alt={image_src}
                    onClick={() => showPopupImagePreview(image_src)}
                  />
                );
              } else if (image_src.url) {
                return undefined;
              } else {
                return (
                  <img
                    key={image_index}
                    style={{ height: "24px", width: "20px" }}
                    src={file_save}
                    alt={image_src}
                    onClick={() => {
                      const isConfirmed = window.confirm("Are you sure you want to download this file?");
                      if (isConfirmed) {
                        window.open(`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`, "_blank");
                      }
                    }}
                  />
                );
              }
            } else {
              const endWithPNGPattern = new RegExp(`^.*.png$`, "i");
              const endWithJPGPattern = new RegExp(`^.*.jpg$`, "i");
              const endWithJPEGPattern = new RegExp(`^.*.jpeg$`, "i");

              if (
                endWithPNGPattern.test(image_src) ||
                endWithJPGPattern.test(image_src) ||
                endWithJPEGPattern.test(image_src)
              ) {
                return (
                  <img
                    key={image_index}
                    style={{ height: "auto", width: "100%" }}
                    src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`}
                    alt={image_src}
                  />
                );
              } else {
                if (image_src?.name) {
                  return (
                    <img
                      key={image_index}
                      style={{ height: "24px", width: "20px" }}
                      src={file_save}
                      alt={image_src}
                      onClick={() => {
                        const isConfirmed = window.confirm("Are you sure you want to download this file?");
                        if (isConfirmed) {
                          window.open(`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`, "_blank");
                        }
                      }}
                    />
                  );
                } else {
                  return null;
                }
              }
            }
          })
        ) : (
          <div>-</div>
        )}
      </div>
    );
  } else if (["video"].includes(colItem.data_type)) {
    return (
      <div key={colItem.id} style={{ ...styleObject, aspectRatio: "16/9", height: "auto" }}>
        <iframe
          style={{
            height: "100%",
            width: "100%",
            border: "none",
            outline: "none"
          }}
          src={dataIs}
          title={colItem.display}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    );
  } else if (["icon"].includes(colItem.data_type)) {
    return (
      <div className={colItem?.className || ""} key={colItem.id} style={styleObject}>
        {colItem?.icon && <i className={colItem.icon}></i>}
      </div>
    );
  } else if (["array"].includes(colItem.data_type)) {
    let bodyClassName = colItem?.className || "";
    return (
      <div key={colItem.id} style={{ ...styleObject, height: "auto" }}>
        {dataIs.map((dataItem, dataItemIndex) => {
          return (
            <div key={dataItemIndex} className={bodyClassName}>
              {colItem?.elements.map((colElement, colElementIndex) => {
                return (
                  <CardItems
                    key={colElementIndex}
                    colItem={colElement}
                    dataConfig={dataItem}
                    layoutDataLineHeight={layoutDataLineHeight}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  } else if (["empty"].includes(colItem.type)) {
    return <div key={colItem.id} style={styleObject}></div>;
  } else if (["label"].includes(colItem.type)) {
    return (
      <div
        className={"card_text_data " + class_name}
        key={colItem.id}
        style={{ ...styleObject, display: "flex", alignItems: "center", ...colItem?.inline_style }}
      >
        <LabelField labelConfig={colItem} />
      </div>
    );
  } else if (["html"].includes(colItem.type)) {
    return (
      <div
        className="app_entry_dashboard_itemS dashboard_title"
        key={colItem.id}
        style={styleObject}
        dangerouslySetInnerHTML={{
          __html: colItem?.content
        }}
      ></div>
    );
  } else if (["variable"].includes(colItem.type)) {
    return (
      <div key={colItem?.id} style={{ ...styleObject, padding: "0px 5px" }}>
        <span className="value">{dataConfig?.[colItem?.id]}</span>
      </div>
    );
  } else if (["activitycard"].includes(colItem.type)) {
    return (
      <div className={colItem.className} key={colItem.id} style={styleObject}>
        <ActivityCardRender
          itemConfig={activityCardsConfig[colItem.id]}
          activityCardsConfig={activityCardsConfig}
          detailsViewDataActivityCard={detailsViewDataActivityCard}
          detailsViewDataTimeline={detailsViewDataTimeline}
          detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
          jobParamsConfig={jobParamsConfig}
          detailsViewDataOrg={detailsViewDataOrg}
          functionObject={functionObject}
          detailsViewItemConfig={detailsViewItemConfig}
        />
      </div>
    );
  } else if (["tableview"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject}>
        <TableViewRenderer
          dataConfig={colItem.config}
          tableConfig={colItem}
          detailsViewData={dataConfig}
          jobParamsConfig={jobParamsConfig}
        />
      </div>
    );
  } else if (["form"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject}>
        <FormComponent
          itemConfig={colItem}
          extraRowData={{
            ...(jobParamsConfig || {}),
            instance_ids: dataConfig["id"] || dataConfig["_id"] ? [dataConfig["id"] || dataConfig["_id"]] : null,
            instance_id: dataConfig["id"] || dataConfig["_id"] || ""
          }}
          functionObject={functionObject}
          jobParamsTokenConfig={jobParamsConfig}
        />
      </div>
    );
  } else if (["timeline"].includes(colItem.type)) {
    return (
      <div className={colItem.className} key={colItem.id} style={styleObject}>
        <TimelineRenderer
          itemConfig={colItem}
          timelineViewData={detailsViewDataTimeline}
          activityCardsConfig={activityCardsConfig}
          detailsViewDataActivityCard={detailsViewDataActivityCard}
          detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
          jobParamsConfig={jobParamsConfig}
          detailsViewDataOrg={detailsViewDataOrg}
          functionObject={functionObject}
          detailsViewItemConfig={detailsViewItemConfig}
        />
      </div>
    );
  } else if (["section"].includes(colItem.type)) {
    return (
      <div key={colItem?.id} style={{ ...styleObject, ...colItem?.inline_style }}>
        <SectionRenderer
          itemConfig={colItem}
          detailsViewData={dataConfig}
          detailsViewDataTimeline={detailsViewDataTimeline}
          activityCardsConfig={activityCardsConfig}
          detailsViewDataActivityCard={detailsViewDataActivityCard}
          detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
          jobParamsConfig={jobParamsConfig}
          detailsViewDataOrg={detailsViewDataOrg}
          functionObject={functionObject}
          detailsViewItemConfig={detailsViewItemConfig}
        />
      </div>
    );
  } else if (["tabview"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={{ ...styleObject, ...colItem?.inline_style }}>
        <TabViewRenderer
          configData={colItem.config}
          detailsViewData={dataConfig}
          detailsViewDataTimeline={detailsViewDataTimeline}
          activityCardsConfig={activityCardsConfig}
          detailsViewDataActivityCard={detailsViewDataActivityCard}
          detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
          jobParamsConfig={jobParamsConfig}
          detailsViewDataOrg={detailsViewDataOrg}
          functionObject={functionObject}
          detailsViewItemConfig={detailsViewItemConfig}
        />
      </div>
    );
  } else if (["image"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject}>
        <img
          src={
            colItem.repo_source
              ? `${process.env.REACT_APP_IMAGE_HOST}/api/v1/public/${colItem.repo_source}`
              : colItem.source
          }
          alt={colItem.name}
          className="imgFit"
        />
      </div>
    );
  } else if (colItem.type === "button") {
    colItem.params = { ...colItem.params, detailsview_id: detailsViewItemConfig?.id || "" };
    return (
      <div key={colItem.id} style={styleObject}>
        <JobActionRenderer itemConfig={colItem} jobParamsConfig={jobParamsConfig} reqData={dataConfig} />
      </div>
    );
  } else if (["dropdown"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject}>
        <DropdownActionRenderer itemConfig={colItem} jobParamsConfig={jobParamsConfig} type="button-group" />
      </div>
    );
  } else if (["button-group"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject}>
        <ButtonGroupField itemConfig={colItem} reqData={dataConfig} jobParamsConfig={jobParamsConfig} />
      </div>
    );
  } else if (["spacer"].includes(colItem.type)) {
    return <div style={{ ...styleObject, height: `${colItem?.height || 40}px` }} key={colItem?.id}></div>;
  } else if (["separator"].includes(colItem.type)) {
    const value = stringToJSONChange(colItem?.custom_css);
    return <hr className="separator_field" style={{ ...styleObject, ...value }} />;
  } else if (["progress-tracker"].includes(colItem.type)) {
    return (
      <div
        style={{ ...styleObject, ...colItem?.inline_style, ...stringToJSONChange(colItem?.custom_css || {}) }}
        key={colItem?.id}
        className={class_name}
      >
        <ProgressBar itemConfig={colItem} data={dataConfig} />
      </div>
    );
  } else if (["progress-bar"].includes(colItem.type)) {
    return (
      <div style={styleObject} key={colItem?.id}>
        <ProgressBarRenderer
          itemConfig={colItem}
          progressBarData={detailsViewDataOrg?.detailsview_data?.progress_bar?.[colItem?.id]}
        />
      </div>
    );
  } else {
    return (
      <div className={"card_text_data " + class_name} key={colItem.id} style={styleObject}>
        <span className="value">{colItem.field_type}</span>
      </div>
    );
  }
};

export default CardItems;

function flattenArray(arr) {
  let flatArray = [];
  arr.forEach((element) => {
    if (Array.isArray(element)) {
      flatArray = flatArray.concat(flattenArray(element));
    } else {
      flatArray.push(element);
    }
  });
  return flatArray;
}
