import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { commonFunction } from "../utils/commonFunction";

const FFSelectField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  const formOptions = storeData[`${namespace}_formOptionsData`];

  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || ""
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);
  // get and check all validation
  const validate = async (value) => {
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
  };

  return (
    <div className="form_field_outer_box">
      <FormControl
        fullWidth
        size="small"
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-color)" // default border color
            },
            "&:hover fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-color)" // border color on hover
            },
            "&.Mui-focused fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-color)" // border color when focused
            },
            "&.Mui-disabled fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-color)", // border color when focused
              backgroundColor: "var(--mui-background-disabled-color)"
            },
            "&.Mui-error fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-error-color)" // border color when there's an error
            }
          }
        }}
      >
        <InputLabel
          id={`${component.id}-label`}
          required={component.validate.required || component.validate.mandatory}
          style={{ fontSize: "15px", color: "#667085" }}
        >
          {component.label}
        </InputLabel>
        <Select
          labelId={`${component.id}-label`}
          id={`${component.id}`}
          value={inputValue}
          label={component.label}
          error={errors.length > 0}
          onChange={(e) => validate(e.target.value)}
          style={{ fontSize: "16px" }}
          size="small"
          disabled={component.disable}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200
              }
            }
          }}
        >
          <MenuItem value="" style={{ fontSize: "14px" }} dense>
            <em style={{ fontStyle: "normal" }}>{component.placeholder}</em>
          </MenuItem>
          {formOptions?.[component?.id]?.length > 0 &&
            formOptions?.[component.id]?.map((element, index) => (
              <MenuItem
                key={`${component.id}_${index}_${component.type}_element`}
                value={element.value}
                style={{ fontSize: "14px", fontFamily: "Poppins" }}
                dense
              >
                {element.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFSelectField;
