import React, { useEffect, useState } from "react";
import { getDataFromDataObjectByKey } from "../../utils/updateConfigFunction";
import CircularProgressBar from "./CircularProgressBar";

const ProgressBar = ({ itemConfig = {}, data = {} }) => {
  const [percentage, setPercentage] = useState(0);
  const [displayValue, setDisplayValue] = useState(0);
  const [progressbarColor, setProgressBarColor] = useState("var(--primary-color)");
  const linearStyle = {
    width: `${percentage}%`,
    background: progressbarColor
  };

  const generatePercentage = (minvalue, maxvalue) => {
    if (!minvalue || !maxvalue || typeof minvalue !== "number" || typeof maxvalue !== "number") {
      setPercentage(0);
      if (itemConfig?.format === "percentage" || !itemConfig?.format) {
        setDisplayValue("0%");
      } else if (itemConfig?.format === "count") {
        setDisplayValue("0/0");
      }
    } else {
      let percent = (minvalue / maxvalue) * 100;
      if (percent > 100) {
        setPercentage(100);
        findColor(100);
      } else {
        percent = parseFloat(percent.toFixed(2));
        findColor(percent);
        setPercentage(percent);
      }
      if (itemConfig?.format === "percentage" || !itemConfig?.format) {
        if (percent > 100) {
          setDisplayValue("100%");
        } else {
          percent = parseFloat(percent.toFixed(2));
          setDisplayValue(`${percent}%`);
        }
      } else if (itemConfig?.format === "count") {
        setDisplayValue(`${minvalue}/${maxvalue}`);
      }
    }
  };

  const findColor = (value) => {
    let sampleColor = itemConfig.color || "var(--primary-color)";
    if (itemConfig?.colorif && itemConfig?.colorif?.length > 0) {
      let colorArr = itemConfig?.colorif.sort((a, b) => parseInt(a.value) - parseInt(b.value));
      for (let i = 0; i < colorArr.length; i++) {
        if (parseInt(colorArr[i].value) >= value) {
          sampleColor = colorArr[i].color;
          break;
        }
      }
      setProgressBarColor(sampleColor);
    } else {
      setProgressBarColor(itemConfig.color || "var(--primary-color)");
    }
  };

  useEffect(() => {
    let minData = [];
    if (itemConfig?.completed_value?.value_type === "hardcoded") {
      minData[0] = Number(itemConfig?.completed_value?.value) || 0;
    } else {
      getDataFromDataObjectByKey(data, minData, `${itemConfig?.completed_value?.value?.id}`);
    }
    const minValue = Number(minData[0]) || 0;
    let maxData = [];
    if (itemConfig?.total_value?.value_type === "hardcoded") {
      maxData[0] = Number(itemConfig?.total_value?.value) || 0;
    } else {
      getDataFromDataObjectByKey(data, maxData, `${itemConfig?.total_value?.value?.id}`);
    }
    const maxValue = Number(maxData[0]) || 0;
    generatePercentage(minValue, maxValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ ...(itemConfig?.width && { width: `${itemConfig?.width}px` }) }}>
      {itemConfig?.view_type === "linear" ? (
        <div className="linear_main_container">
          <div className="linear_graph_container">
            <div className="linear_bar">
              <div className="linear_fill" style={linearStyle}></div>
            </div>
            <span className="linear_value">{displayValue}</span>
          </div>
          <span className="linear_title">{itemConfig?.title}</span>
        </div>
      ) : (
        <div>
          <CircularProgressBar
            percentage={percentage}
            itemConfig={itemConfig}
            value={displayValue}
            progressbarColor={progressbarColor}
          />
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
