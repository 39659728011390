import React, { useEffect, useState } from "react";
import "./DetailsViewRender.css";
import DetailsViewItem from "./DetailsViewItem";
// import axios from "axios";
// import { getApiCallHeadersData } from "../../utils/storageFunction";
import { toastErrorMessageStyle } from "../../utils/apiCallFunction";
import toast from "react-hot-toast";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { fetchDetailsView } from "../../redux/detailsview/detailsviewaction";

const DetailsViewRender = ({ itemConfig, callFrom, jobParamsTokenConfig, functionObject = {} }) => {
  const [detailsViewData, setDetailsViewData] = useState();
  const [jobParamsConfig, setJobParamsConfig] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const detailsviewdata = useSelector((state) => state.detailsviewstore?.[`${itemConfig?.id}_detailsviewdata`]);
  const dispatch = useDispatch();

  const apiCallFunction = async () => {
    try {
      setShowLoader(true);
      await dispatch(
        fetchDetailsView(itemConfig?.id, {
          detailsview_id: itemConfig?.id,
          instance_id:
            jobParamsTokenConfig?.instance_id || jobParamsTokenConfig?.id || jobParamsTokenConfig?._id || null
        })
      );
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    } finally {
      setShowLoader(false);
    }
    setJobParamsConfig({
      sm_id: itemConfig.config.table_id,
      instance_id:
        detailsviewdata?.data?.detailsview_data?.form_data?.id ||
        jobParamsTokenConfig.instance_id ||
        jobParamsTokenConfig.id ||
        jobParamsTokenConfig._id ||
        null,
      instance_ids: jobParamsTokenConfig.instance_ids || null
    });
  };

  useEffect(() => {
    if (itemConfig?.id && jobParamsTokenConfig) {
      apiCallFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (detailsviewdata?.data) {
      setDetailsViewData(detailsviewdata?.data);
    } else {
      setDetailsViewData({});
    }
  }, [detailsviewdata]);

  if (!itemConfig.config) {
    return undefined;
  }
  if (!functionObject?.reloadFunction) {
    functionObject["reloadFunction"] = apiCallFunction;
  }
  if (showLoader) {
    return (
      <div
        style={{
          width: "100%",
          margin: "auto",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Skeleton variant="rounded" width="100%" height={40} style={{ margin: "auto" }} />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="90%"
          height={60}
          style={{ margin: "auto", marginTop: "5px" }}
        />
        <Skeleton variant="rounded" width="90%" height={35} style={{ margin: "auto", marginTop: "15px" }} />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="90%"
          height={35}
          style={{ margin: "auto", marginTop: "15px" }}
        />
        <Skeleton variant="rounded" width="90%" height={35} style={{ margin: "auto", marginTop: "15px" }} />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="90%"
          height={35}
          style={{ margin: "auto", marginTop: "15px" }}
        />
        <Skeleton variant="rounded" width="90%" height={35} style={{ margin: "auto", marginTop: "15px" }} />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="90%"
          height={35}
          style={{ margin: "auto", marginTop: "15px" }}
        />
        <Skeleton variant="rounded" width="90%" height={35} style={{ margin: "auto", marginTop: "15px" }} />
        <Skeleton animation="wave" variant="rounded" width="15%" height={35} style={{ margin: "15px 0 0 50px" }} />
      </div>
    );
  }
  if (Object.keys(detailsViewData || {}).length > 0) {
    return (
      <DetailsViewItem
        itemConfig={detailsViewData?.elements}
        activityCardsConfig={detailsViewData?.activity_cards}
        detailsViewDataFormData={detailsViewData?.detailsview_data?.form_data || {}}
        detailsViewDataTimeline={detailsViewData?.detailsview_data?.timeline_data || []}
        detailsViewDataActivityCard={detailsViewData?.detailsview_data?.activity_card_data || []}
        detailsViewDataActivitiesHistory={detailsViewData?.detailsview_data?.allowed_activities_history || {}}
        detailsViewDataActivityRecord={detailsViewData?.detailsview_data?.activity_recordview_data}
        detailsViewDataOrg={detailsViewData}
        detailsViewItemConfig={itemConfig}
        jobParamsConfig={jobParamsConfig}
        functionObject={functionObject}
      />
    );
  } else {
    return <></>;
  }
};

export const DetailsViewRenderPopup = ({ itemConfig, rowData, functionObject = {} }) => {
  const [detailsViewData, setDetailsViewData] = useState();
  const [jobParamsConfig, setJobParamsConfig] = useState({});

  const apiCallFunction = async () => {
    setDetailsViewData(itemConfig?.data?.detailsview_data || {});
    setJobParamsConfig({ sm_id: itemConfig.data.table_id || "", instance_id: rowData.instance_id || "" });
  };

  useEffect(() => {
    apiCallFunction(rowData, itemConfig.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!functionObject?.reloadFunction) {
    functionObject["reloadFunction"] = apiCallFunction;
  }
  if (Object.keys(detailsViewData || {}).length > 0) {
    return (
      <DetailsViewItem
        itemConfig={itemConfig.data.elements}
        activityCardsConfig={itemConfig.data.activity_cards}
        detailsViewDataFormData={detailsViewData?.form_data || {}}
        detailsViewDataTimeline={detailsViewData?.timeline_data || []}
        detailsViewDataActivityCard={detailsViewData?.activity_card_data || []}
        detailsViewDataActivitiesHistory={detailsViewData?.allowed_activities_history || {}}
        detailsViewDataActivityRecord={detailsViewData?.activity_recordview_data}
        detailsViewDataOrg={detailsViewData}
        detailsViewItemConfig={itemConfig}
        jobParamsConfig={jobParamsConfig}
        detailsViewPopup={true}
        functionObject={functionObject}
      />
    );
  } else {
    return <></>;
  }
};

export const DetailsViewRenderForm = ({ config, detailsViewData }) => {
  // eslint-disable-next-line no-unused-vars
  const [itemConfig, setItemConfig] = useState({
    config: {
      elements: config.elements,
      activity_cards: {}
    },
    id: ""
  });
  if (!detailsViewData) {
    return <div></div>;
  } else {
    return (
      <DetailsViewItem
        itemConfig={itemConfig?.config?.elements}
        activityCardsConfig={itemConfig?.config?.activity_cards}
        detailsViewDataFormData={detailsViewData || {}}
        detailsViewDataTimeline={[]}
        detailsViewDataActivityCard={[]}
        detailsViewDataActivitiesHistory={detailsViewData?.allowed_activities_history || {}}
        detailsViewItemConfig={itemConfig}
        detailsViewDataActivityRecord={detailsViewData?.activity_recordview_data}
        detailsViewDataOrg={{}}
        functionObject={{
          reloadFunction: () => {
            setItemConfig({
              config: {
                elements: config.elements,
                activity_cards: {}
              },
              id: ""
            });
          }
        }}
      />
    );
  }
};

export default DetailsViewRender;
