import React from "react";
import { Popover, Typography } from "@mui/material";

const HelpTextPopup = ({ handleClose, help_text, anchorEl }) => {
  const open = Boolean(anchorEl);
  const id = open ? "info-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      anchorEl={anchorEl}
    >
      <Typography sx={{ p: 2 }}>{help_text}</Typography>
    </Popover>
  );
};

export default HelpTextPopup;
