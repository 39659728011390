import { Autocomplete, Paper, Stack, TextField } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { commonFunction } from "../utils/commonFunction";
import { useSelector } from "react-redux";
import HelpTextIcon from "./HelpTextIcon";

const FFLearningTextField = ({ component, currentConfigKey, namespace }) => {
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [outputOption, setOutputOption] = useState({
    value: commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || ""
  });

  function removeEmptyLabel(objects) {
    return objects.filter((obj) => {
      if (typeof obj === "string") {
        return obj?.trim() !== "";
      }
      return false;
    });
  }

  // eslint-disable-next-line no-unused-vars

  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);

  let inputOption = removeEmptyLabel(component.data.values);

  if (component?.sort) {
    inputOption.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setOutputOption(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    if (component.allow_new) {
      setOutputOption({ ...{ value: value } });
      await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
    } else {
      if (inputOption.includes(value)) {
        setOutputOption({ ...{ value: value } });
        await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
      } else {
        setOutputOption({ ...{ value: "" } });
        await commonFunction.callChackFiledFunction(namespace, "", currentConfigKey, component);
      }
    }
  };

  return (
    <div>
      <Stack spacing={2} width={"100%"}>
        <Autocomplete
          id={component?.id || component?._id}
          options={inputOption}
          getOptionLabel={(option) => {
            return option || JSON.stringify(option);
          }}
          value={outputOption?.value || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label={component?.label}
              value={outputOption?.value}
              onChange={({ target: { value } }) => setOutputOption({ ...{ value: value } })}
              onBlur={({ target: { value } }) => validate(value)}
              required={component?.validate?.required || component.validate.mandatory}
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    {(outputOption?.value === "" || outputOption === "") && component?.help_text && (
                      <HelpTextIcon help_text={component?.help_text} />
                    )}
                  </>
                )
              }}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "var(--mui-label-color)"
                },
                "& .MuiInputLabel-root.Mui-error": {
                  color: "var(--mui-label-error-color)" // color when there's an error
                },
                "& .MuiInputLabel-root.MuiInputLabel-shrink": {
                  color: "var(--mui-label-shrink-color)" // color when the label is at the top (shrunk)
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)" // default border color
                  },
                  "&:hover fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)" // border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)" // border color when focused
                  },
                  "&.Mui-disabled fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)", // border color when focused
                    backgroundColor: "var(--mui-background-disabled-color)"
                  },
                  "&.Mui-error fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-error-color)" // border color when there's an error
                  }
                }
              }}
            />
          )}
          freeSolo
          onChange={(e, value) => {
            validate(value);
          }}
          filterOptions={(options, { inputValue }) => {
            if (!component.show_default_option && inputValue.trim() === "") {
              return [];
            }
            const inputValues = inputValue.trim().toLowerCase().split(" ");
            return options.filter((option) => {
              const labelWords = option.toLowerCase().split(" ");
              const firstWord = labelWords[0];

              return inputValues.every((val, index) => {
                if (index === 0) {
                  return firstWord.startsWith(val);
                } else {
                  return labelWords.slice(1).some((word) => word.startsWith(val));
                }
              });
            });
          }}
          ListboxProps={{ sx: { fontSize: "14px" } }}
          PaperComponent={({ children }) => <Paper style={{ maxHeight: 200, overflow: "auto" }}>{children}</Paper>}
        />
      </Stack>
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id || component._id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default FFLearningTextField;
