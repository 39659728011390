import React, { useLayoutEffect, useState, useCallback, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { commonFunction } from "../utils/commonFunction";
import { useSelector } from "react-redux";
import axios from "axios";
import { getApiCallLocalPath } from "../../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../../utils/storageFunction";
import HelpTextIcon from "./HelpTextIcon";

function FFAppUserField({ component, currentConfigKey, namespace }) {
  const { user } = useSelector((state) => state.userviewstore);
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  let formConfigData = storeData?.[`${namespace}_formConfigData`] || {};

  const [outputOption, setOutputOption] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || ""
  );
  const [inputOption, setInputOption] = useState([]);

  const apiCallAndGetDataDebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const apiCallAndGetData = async (term) => {
    let { data } = await axios.post(
      `${getApiCallLocalPath()}api/v1/dynamic`,
      {
        Params: component?.app_user_filter || {},
        data: {
          form_id: formConfigData.id,
          field_id: component.id,
          activity_id: formConfigData.sm_activity_id,
          entity_id: formConfigData.entity_id,
          sm_id: formConfigData.sm_id
        },
        function_name: "get_app_users"
      },
      { headers: getApiCallHeadersData() }
    );

    let viewData = data.data?.users || [];
    setInputOption(viewData);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiCallAndGetDataOptimizedFun = useCallback(apiCallAndGetDataDebounce(apiCallAndGetData), []);

  useEffect(() => {
    apiCallAndGetDataOptimizedFun();
    apiCallAndGetDataDebounce(apiCallAndGetData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputOption.length > 0 && !outputOption && component.prefill_current_user) {
      for (let index = 0; index < inputOption.length; index++) {
        const element = inputOption[index];
        if (element.id === user.login_user_id) {
          validate(element);
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputOption]);

  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setOutputOption(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    setOutputOption(value);
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component, false);
    // setInputOption([]);
  };

  if (component.show) {
    return (
      <div className="form_field_outer_box">
        <Stack spacing={2} width={"100%"}>
          <Autocomplete
            id={component.id}
            options={inputOption}
            disabled={component.disable}
            getOptionLabel={(option) => option.name}
            value={outputOption || null}
            renderInput={(params) => (
              <TextField
                {...params}
                label={component.label}
                // onChange={(e) => apiCallAndGetDataOptimizedFun(e.target.value)}
                required={component.validate.required || component.validate.mandatory}
                size="small"
                disabled={component.disable}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                      {outputOption === "" && component?.help_text && <HelpTextIcon help_text={component?.help_text} />}
                    </>
                  )
                }}
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "var(--mui-label-color)"
                  },
                  "& .MuiInputLabel-root.Mui-error": {
                    color: "var(--mui-label-error-color)" // color when there's an error
                  },
                  "& .MuiInputLabel-root.MuiInputLabel-shrink": {
                    color: "var(--mui-label-shrink-color)" // color when the label is at the top (shrunk)
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: "var(--mui-border-width)",
                      borderColor: "var(--mui-border-color)" // default border color
                    },
                    "&:hover fieldset": {
                      borderWidth: "var(--mui-border-width)",
                      borderColor: "var(--mui-border-color)" // border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderWidth: "var(--mui-border-width)",
                      borderColor: "var(--mui-border-color)" // border color when focused
                    },
                    "&.Mui-disabled fieldset": {
                      borderWidth: "var(--mui-border-width)",
                      borderColor: "var(--mui-border-color)", // border color when disabled
                      backgroundColor: "var(--mui-background-disabled-color)"
                    },
                    "&.Mui-error fieldset": {
                      borderWidth: "var(--mui-border-width)",
                      borderColor: "var(--mui-border-error-color)" // border color when there's an error
                    }
                  }
                }}
              />
            )}
            onChange={(e, value) => {
              validate(value);
            }}
            ListboxProps={{ sx: { fontSize: "14px" } }}
          />
        </Stack>
        {errors.length > 0 && (
          <>
            <div className="invalid_feedback">
              {errors.map((error, index) => (
                <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                  {error}
                </p>
              ))}
            </div>
          </>
        )}
      </div>
    );
  } else {
    return null;
  }
}

export default FFAppUserField;
