import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import { TextField } from "@mui/material";
import { commonFunction } from "../utils/commonFunction";
import HelpTextIcon from "./HelpTextIcon";

const FFCurrencyField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || ""
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) {
      let value = commonFunction.getKeyValueFromForm(namespace, currentConfigKey);
      if (typeof value === "number") {
        value = JSON.stringify(value);
      } else if (typeof value === "string") {
      } else {
        value = "";
      }
      setInputValue(value || "");
    } else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
  };

  const onchange = (e) => {
    let number = e.target.value;
    let max_digit = component?.validate?.max_digit || -1;
    let decimal_point = component?.validate?.decimal_point || -1;

    let numberArr = number.split(".");

    if (max_digit !== -1) {
      let num1 = numberArr[0];
      if (num1.length > max_digit) {
        numberArr[0] = num1.substring(0, max_digit);
      }
    }
    if (decimal_point !== -1) {
      let num2 = numberArr[1];
      if (num2) {
        if (num2.length > decimal_point) {
          numberArr[1] = num2.substring(0, decimal_point);
        }
      }
    }

    setInputValue(numberArr.join("."));
  };

  return (
    <div className="form_field_outer_box">
      <TextField
        id={component.id}
        type="number"
        fullWidth
        error={errors.length > 0}
        label={`${component?.label || ""} ${component?.currency ? " [ " + component?.currency + " ] " : ""}`}
        value={inputValue}
        onChange={onchange}
        onBlur={(e) => validate(e.target.value)}
        disabled={component.disable}
        required={component.validate.required || component.validate.mandatory}
        InputProps={{
          endAdornment: inputValue === "" && component?.help_text && <HelpTextIcon help_text={component?.help_text} />
        }}
        size="small"
        sx={{
          "& .MuiInputLabel-root": {
            color: "var(--mui-label-color)"
          },
          "& .MuiInputLabel-root.Mui-error": {
            color: "var(--mui-label-error-color)" // color when there's an error
          },
          "& .MuiInputLabel-root.MuiInputLabel-shrink": {
            color: "var(--mui-label-shrink-color)" // color when the label is at the top (shrunk)
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-color)" // default border color
            },
            "&:hover fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-color)" // border color on hover
            },
            "&.Mui-focused fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-color)" // border color when focused
            },
            "&.Mui-disabled fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-color)", // border color when disabled
              backgroundColor: "var(--mui-background-disabled-color)"
            },
            "&.Mui-error fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-error-color)" // border color when there's an error
            }
          }
        }}
      />
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFCurrencyField;
