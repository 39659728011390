import React, { useEffect } from "react";
import axios from "axios";
import { getApiCallHeadersData } from "./storageFunction";

const LocationFetch = () => {
  useEffect(() => {
    // Function to fetch the location
    const fetchLocation = () => {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const { latitude, longitude } = pos.coords;
          uploadLocation(latitude, longitude);
        },
        (error) => console.error("Error fetching location:", error),
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    };

    // Call fetchLocation immediately when component mounts
    fetchLocation();

    // Fetch location every 5 minutes (300000 ms)
    const intervalId = setInterval(fetchLocation, 300000);

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const uploadLocation = async (latitude, longitude) => {
    try {
      const coords = {
        latitude: latitude,
        longitude: longitude
      };
      const { data } = await axios.post(`${process.env.REACT_APP_LOGIN_URL}/api/v1/location`, coords, {
        headers: getApiCallHeadersData()
      });
      localStorage.setItem("user_last_location", JSON.stringify(data));
    } catch (error) {
      console.error("Error uploading location:", error);
    }
  };

  return <></>;
};

export default LocationFetch;
