import toast from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import axios from "axios";
import { getApiCallHeadersData } from "../../utils/storageFunction";

export const DETAILS_VIEW_DATA = "DETAILS_VIEW_DATA";

export const setDetailsViewData = (payload) => {
  return {
    type: DETAILS_VIEW_DATA,
    payload
  };
};

export const fetchDetailsView = (namespace, jobParamsTokenConfig) => async (dispatch) => {
  try {
    if (Object.keys(jobParamsTokenConfig)?.length > 0) {
      const apiPayload = {
        data: {},
        function_name: "show_detailsview_popup",
        params: {
          detailsview_id: namespace,
          instance_id: jobParamsTokenConfig?.instance_id || jobParamsTokenConfig?.id || null,
          front_end: {
            action_config: {},
            params_config: {}
          },
          token_data: {}
        }
      };
      const { data } = await axios.post(
        `${getApiCallLocalPath()}api/v1/dynamic`,
        { ...apiPayload },
        { headers: getApiCallHeadersData() }
      );
      const payload = {
        namespace: namespace,
        payloadData: data?.data?.detailsview_config
      };
      dispatch(setDetailsViewData(payload));
    }
  } catch (error) {
    toast.error(error?.response?.data?.message || "Details not found", toastErrorMessageStyle());
  }
};
