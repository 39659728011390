import { IconButton, InputAdornment } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import HelpTextPopup from "./HelpTextPopup";

const HelpTextIcon = ({ help_text = "" }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <InputAdornment position="end">
        <IconButton onClick={handleIconClick}>
          <InfoIcon />
        </IconButton>
      </InputAdornment>
      <HelpTextPopup help_text={help_text} handleClose={handleClose} anchorEl={anchorEl} />
    </>
  );
};

export default HelpTextIcon;
