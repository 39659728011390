import React from "react";

import FFTextField from "../components/FFTextField";
import FFEmailField from "../components/FFEmailField";
import FFNumberField from "../components/FFNumberField";
import FFToggleField from "../components/FFToggleField";
import FFCheckboxField from "../components/FFCheckboxField";
import FFPasswordField from "../components/FFPasswordField";
import FFDateField from "../components/FFDateField";
import FFTimeField from "../components/FFTimeField";
import FFDateTimeField from "../components/FFDateTimeField";
import FFFileField from "../components/FFFileField";
import FFTextAreaField from "../components/FFTextAreaField";
import FFSelectField from "../components/FFSelectField";
import FFSelectboxesField from "../components/FFSelectboxesField";
import FFRadioField from "../components/FFRadioField";
import FFContainerField from "../components/FFContainerField";
import FFContentField from "../components/FFContentField";
import FFLookupField from "../components/FFLookupField";
import FFButtonField from "../components/FFButtonField";
import FFGeoLocationField from "../components/FFGeoLocationField";
import FFRecordSetRenderer from "../components/FFRecordSetRenderer";
import FFRecordSetListRenderer from "../components/FFRecordSetListRenderer";
import FFAppUserField from "../components/FFAppUserField";
import FFLearningTextField from "../components/FFLearningTextField";
import FFIdGenField from "../components/FFIdGenField";
import FFLearningTagsField from "../components/FFLearningTagsField";
import FFLabelField from "../components/FFLabelField";
import FFLookupSMField from "../components/FFLookupSMField";
import FFRatingsField from "../components/FFRatingsField";
import FFSelectChipsField from "../components/FFSelectChipsField";
import FFPhone from "../components/FFPhone";
import FFGridField from "../components/FFGridField";
import FFRichGridField from "../components/FFRichGridField";
import FFCurrencyField from "../components/FFCurrencyField";
import FFSlider from "../components/FFSlider";
import FFUrlField from "../components/FFUrlField";
// import FFRichTextField from "../components/FFRichTextField";
import FFPercentageField from "../components/FFPercentageField";
import FFMultiDateField from "../components/FFMultiDateField";

const FieldView = ({ colItem, dataObject, styleObject, currentConfigKey, namespace, parentField = "section" }) => {
  if (dataObject.show || ["app-user"].includes(colItem.element_type)) {
    dataObject.parentField = parentField;

    switch (colItem.element_type) {
      case "disabled":
        dataObject.disable = true;
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFTextField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );

      case "textfield":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFTextField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "phone":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFPhone component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "email":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFEmailField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "number":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFNumberField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "password":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFPasswordField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "checkbox":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFCheckboxField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "toggle":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFToggleField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "day":
      case "date":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFDateField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "multi-date":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFMultiDateField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "time":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFTimeField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "datetime":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFDateTimeField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );

      case "currency":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFCurrencyField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );

      case "file":
      case "image":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFFileField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "textarea":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFTextAreaField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "select":
      case "number-select":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFSelectField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "selectboxes":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding form_field_grid_box_outer_overY">
            <FFSelectboxesField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "radio":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding form_field_grid_box_outer_overY">
            <FFRadioField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "content":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFContentField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "lookup":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFLookupField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "lookup-sm":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFLookupSMField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "app-user":
        if (dataObject.show) {
          return (
            <div style={styleObject} className="form_field_grid_box_outer_padding">
              <FFAppUserField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
            </div>
          );
        } else {
          return <FFAppUserField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />;
        }
      case "container":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFContainerField
              containerComponent={dataObject}
              currentConfigKey={currentConfigKey}
              namespace={namespace}
            />
          </div>
        );
      case "recordset":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFRecordSetRenderer
              recordsetComponent={dataObject}
              currentConfigKey={currentConfigKey}
              namespace={namespace}
            />
          </div>
        );
      case "recordset-list":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFRecordSetListRenderer
              recordsetComponent={dataObject}
              currentConfigKey={currentConfigKey}
              namespace={namespace}
            />
          </div>
        );
      case "grid":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFGridField gridComponent={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "rich-grid":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFRichGridField gridComponent={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "geolocation":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFGeoLocationField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "learning-text":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFLearningTextField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "learning-tags":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFLearningTagsField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "id-gen":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFIdGenField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );

      case "button":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFButtonField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "ratings":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFRatingsField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );

      case "label":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFLabelField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );
      case "select-chips":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFSelectChipsField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );

      case "slider":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFSlider component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );

      case "empty":
        return <div style={styleObject} className="form_field_grid_box_outer_padding"></div>;
      case "spacer":
        return <div style={{ ...styleObject, height: "40px" }} className="form_field_grid_box_outer_padding"></div>;

      case "separator":
        return (
          <div
            style={{
              ...styleObject,
              borderTop: "1px solid var(--border)",
              margin: "12px 0 8px 0"
            }}
          ></div>
        );

      case "url":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFUrlField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );

      case "percentage":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            <FFPercentageField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} />
          </div>
        );

      case "richtext":
        return (
          <div style={styleObject} className="form_field_grid_box_outer_padding">
            {/* <FFRichTextField component={dataObject} currentConfigKey={currentConfigKey} namespace={namespace} /> */}
          </div>
        );

      case "audio":
        return <div style={styleObject} className="form_field_grid_box_outer_padding"></div>;

      case "video":
        return <div style={styleObject} className="form_field_grid_box_outer_padding"></div>;

      case "signature":
        return <div style={styleObject} className="form_field_grid_box_outer_padding"></div>;

      case "counter":
        return <div style={styleObject} className="form_field_grid_box_outer_padding"></div>;

      default:
        return <div style={styleObject}>{colItem.element_type}</div>;
    }
  } else {
    return null;
  }
};

export default FieldView;
