import axios from "axios";
import { getFormConfigData, setFormPageErrorColor, updateFormData } from "../../../redux/formview/formviewaction";
import { store } from "../../../redux/store";
import { renderFunction } from "./renderFunction";
import validation from "../../validation/index";
import toast from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../../utils/storageFunction";
import { changeDataTypeStringToAny } from "./formUtilsFunction";
import { form_images_store } from "./store";
import { formDataPrefill } from "./prefillData";

export class commonFunction {
  static getKeyValueFromForm(namespace, keyArray, keyName = "value") {
    // get form data from redux
    let dataObject = store.getState()?.["formviewstore"]?.[`${namespace}_formData`];
    if (!dataObject) dataObject = {};
    // geting keys by string in array format
    keyArray = keyArray.split(":");
    let index = 0;
    while (index < keyArray.length) {
      const key = keyArray[index];
      if (dataObject[key]) {
        dataObject = dataObject[key];
      } else {
        return undefined;
      }
      index++;
    }

    if (keyName === "self") {
      return dataObject[keyName];
    }

    return dataObject[keyName];
  }

  static getKeyErrorFromForm(namespace, keyArray) {
    // get form data from redux
    let dataObject = store.getState()?.["formviewstore"]?.[`${namespace}_formData`];
    if (!dataObject) dataObject = {};
    // geting keys by string in array format
    keyArray = keyArray.split(":");
    let index = 0;
    while (index < keyArray.length) {
      const key = keyArray[index];
      if (dataObject[key]) {
        dataObject = dataObject[key];
      } else {
        return undefined;
      }
      index++;
    }
    return dataObject.errors;
  }

  static callChackLookupFiledFunction(namespace, value, keyArray, component) {
    let form_data = store.getState()?.["formviewstore"]?.[`${namespace}_formData`] || {};
    let dataObject = form_data;

    keyArray = keyArray.split(":");
    let index = 0;
    while (index < keyArray.length - 1) {
      const key = keyArray[index];
      // add object in form data
      if (!dataObject[key]) dataObject[key] = { errors: [] };

      dataObject = dataObject[key];
      index++;
    }

    // if error count undefined set count is 0 and remove count error
    if (!form_data?.[keyArray[0]]?.["errorCount"]) form_data[keyArray[0]]["errorCount"] = 0;
    if (form_data?.[keyArray[0]]["errorCount"])
      form_data[keyArray[0]]["errorCount"] -= dataObject[keyArray[keyArray.length - 1]].errors.length;

    let allErrors = [];

    if (component?.validate?.required && !value) {
      allErrors = ["required"];
    }

    if (!dataObject[keyArray[keyArray.length - 1]]) {
      dataObject[keyArray[keyArray.length - 1]] = { errors: [] };
    }
    dataObject[keyArray[keyArray.length - 1]].value = value?.id;
    dataObject[keyArray[keyArray.length - 1]].valueObject = value;
    dataObject[keyArray[keyArray.length - 1]].errors = allErrors;
    form_data[keyArray[0]]["errorCount"] += dataObject[keyArray[keyArray.length - 1]].errors.length;

    store.dispatch(updateFormData(namespace, { ...form_data }));
  }

  static callChackGridFiledFunction(namespace, value, keyArray, component) {
    let form_data = store.getState()?.["formviewstore"]?.[`${namespace}_formData`] || {};
    let dataObject = form_data;

    keyArray = keyArray.split(":");
    let index = 0;
    while (index < keyArray.length - 1) {
      const key = keyArray[index];
      // add object in form data
      if (!dataObject[key]) dataObject[key] = { errors: [] };

      dataObject = dataObject[key];
      index++;
    }

    dataObject[keyArray[keyArray.length - 1]] = value;

    store.dispatch(updateFormData(namespace, { ...form_data }));
  }

  static callFiledUpdateFunction(namespace, value, keyArray) {
    let form_data = store.getState()?.["formviewstore"]?.[`${namespace}_formData`] || {};
    let dataObject = form_data;

    keyArray = keyArray.split(":");
    let index = 0;
    while (index < keyArray.length) {
      const key = keyArray[index];
      // add object in form data
      if (!dataObject[key]) dataObject[key] = { errors: [] };

      dataObject = dataObject[key];
      index++;
    }
    dataObject.value = value;
    store.dispatch(updateFormData(namespace, { ...form_data }));
  }

  static async callChackFiledFunction(namespace, value, keyArrayStr, component, callOnChangeFlag = true) {
    let validationClass = new validation(store.getState()?.["formviewstore"]?.[`${namespace}_formCustomFunData`] || []);
    let formConfigData = store.getState()?.["formviewstore"]?.[`${namespace}_formConfigData`];
    let form_data = store.getState()?.["formviewstore"]?.[`${namespace}_formData`] || {};

    let allErrors = [];

    let formsArray = store.getState()?.["formviewstore"]?.[`${namespace}_formConfigData`].sections;
    let dateKeysArray = store.getState()?.["formviewstore"]?.[`${namespace}_formFieldKeyAndType`];

    let dataObject = form_data;
    keyArrayStr = keyArrayStr.replaceAll("_recordset_list", "");
    keyArrayStr = keyArrayStr.replaceAll("_grid", "");
    keyArrayStr = keyArrayStr.replaceAll("_rich_grid", "");
    let keyArray = keyArrayStr.split(":");
    let index = 0;
    let lastKey = keyArray[keyArray.length - 1];
    while (index < keyArray.length - 1) {
      const key = keyArray[index];
      // add object in form data
      if (!dataObject[key]) dataObject[key] = { errors: [] };
      dataObject = dataObject[key];
      index++;
    }
    // if error count undefined set count is 0 and remove count error
    if (!form_data?.[keyArray[0]]?.["errorCount"]) {
      form_data[keyArray[0]]["errorCount"] = 0;
    }
    if (form_data?.[keyArray[0]]["errorCount"]) {
      form_data[keyArray[0]]["errorCount"] -= dataObject?.[lastKey]?.errors?.length || 0;
    }

    // update value and error in redux
    if (component.type === "file" || component.type === "image") {
      if (!dataObject[lastKey]) {
        dataObject[lastKey] = { errors: [] };
        dataObject = dataObject[lastKey];
      } else {
        dataObject = dataObject[lastKey];
      }
      dataObject.value = value["value"];
      dataObject.errors = [...allErrors, ...value["errors"]];
      let array = dataObject.value;
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (element["error"] && dataObject.errors.indexOf(element["error"]) === -1)
          dataObject.errors.push(element["error"]);
      }
    } else {
      if (!dataObject[lastKey]) {
        dataObject[lastKey] = { errors: [] };
        dataObject = dataObject[lastKey];
      } else {
        dataObject = dataObject[lastKey];
      }
      dataObject.value = value;
      dataObject.errors = allErrors;
    }

    // form_data[keyArray[0]]["errorCount"] += dataObject?.errors?.length || 0;

    let fullformError = commonFunction.callGetKeyCompleteErrorDataFromForm(
      namespace,
      formsArray,
      JSON.parse(JSON.stringify(form_data || {}))
    );
    let recordset_list_flagObject = {};
    let fullformData = commonFunction.callGetKeyCompleteDataFromForm(
      namespace,
      "value",
      formsArray,
      JSON.parse(JSON.stringify(form_data || {})),
      dateKeysArray,
      recordset_list_flagObject
    );

    let formDataObj;
    if (fullformData) {
      formDataObj = commonFunction.getOnlyDataFromObject(fullformData, namespace, false);
    }

    let newData = JSON.parse(JSON.stringify(formDataObj || {}));

    let chackRes = await validationClass.chackFiledFunction(
      value,
      component.validate,
      component.type,
      newData,
      namespace
    );
    allErrors = chackRes?.errors || [];

    let errdataObject = fullformError;
    index = 0;
    lastKey = keyArray[keyArray.length - 1];
    while (index < keyArray.length - 1) {
      const key = keyArray[index];
      if (!errdataObject[key]) errdataObject[key] = { errors: [] };
      errdataObject = errdataObject[key];
      index++;
    }
    if (component.parentField === "recordset-list" || component.parentField === "grid") {
      if (Array.isArray(errdataObject)) {
        errdataObject = errdataObject[errdataObject.length - 1];
        errdataObject[lastKey] = allErrors;
      } else {
        errdataObject[lastKey] = allErrors;
      }
    } else {
      errdataObject[lastKey] = allErrors;
    }

    let formFillData = formDataPrefill(
      chackRes?.data || {},
      fullformError || {},
      formConfigData,
      "inside",
      {},
      component?.parentField,
      recordset_list_flagObject
    );
    if (formFillData[keyArray[0]]) {
      formFillData[keyArray[0]]["errorCount"] = (form_data?.[keyArray[0]]?.["errorCount"] || 0) + allErrors.length;
    }

    store.dispatch(updateFormData(namespace, { ...formFillData }));

    // call if no error
    if (callOnChangeFlag) {
      commonFunction.callOnChangeFunction(namespace, value, component, allErrors.length === 0, keyArray);
    }
  }

  static async callOnChangeFunction(namespace, value, component, flag, keyArray) {
    let form_data = store.getState()?.["formviewstore"]?.[`${namespace}_formData`];
    let formConfigData = store.getState()?.["formviewstore"]?.[`${namespace}_formConfigData`];
    let pageNumber = store.getState()?.["formviewstore"]?.[`${namespace}_formPageNumber`];
    form_data = commonFunction.callDefaultActionFunction(component.id, false, true, form_data, formConfigData);
    // call onChange functions [set_options, set_value, show, hide]
    if (flag && component.on_change) {
      // check and apply all action
      for (let index = 0; index < component.on_change.length; index++) {
        const element = component.on_change[index];

        let onChangeFunFlag = false;
        switch (component.type) {
          case "number":
            if (element.gt && element.lt) {
              onChangeFunFlag = value > element.gt && value < element.lt;
            } else if (!element.gt && element.lt) {
              onChangeFunFlag = value < element.lt;
            } else if (element.gt && !element.lt) {
              onChangeFunFlag = value > element.gt;
            } else if (!element.gt && !element.lt) {
              onChangeFunFlag = true;
            }

            if (!value) {
              onChangeFunFlag = true;
            }
            break;
          case "geolocation":
          case "email":
          case "date":
          case "multi-date":
          case "time":
          case "day":
          case "datetime":
          case "lookup":
          case "lookup-sm":
          case "app-user":
            onChangeFunFlag = false;
            break;
          case "checkbox":
          case "toggle":
            onChangeFunFlag = value;
            break;
          case "selectboxes":
            let valuesArray = Object.values(value);
            for (let index = 0; index < valuesArray.length; index++) {
              valuesArray[index] = valuesArray[index].toLowerCase();
            }
            onChangeFunFlag = !element.has || valuesArray.indexOf(element.has.length) !== -1;
            break;
          default:
            onChangeFunFlag = true;
            if (element.eq) onChangeFunFlag = value.toLowerCase() === element.eq.toLowerCase();
            if (!onChangeFunFlag && element.has) onChangeFunFlag = element.has.includes(value);
            break;
        }

        if (onChangeFunFlag) {
          for (let eleIndex = 0; eleIndex < element.action.length; eleIndex++) {
            const item = element.action[eleIndex];
            switch (item.type) {
              case "set_options":
                renderFunction.setItemOptions(namespace, value, item, formConfigData.sections);
                break;
              case "set_value":
                let keyArrayCopy = JSON.parse(JSON.stringify(keyArray));
                renderFunction.setItemValue(namespace, value, item, formConfigData.sections, form_data, keyArrayCopy);
                break;
              case "show":
                renderFunction.showFormItem(item, formConfigData.sections);
                break;
              case "hide":
                renderFunction.hideFormItem(item, formConfigData.sections);
                break;
              case "copy_value":
                renderFunction.copyItemValueAndUse(item, formConfigData.sections, form_data);
                break;
              default:
                break;
            }
          }
        }
      }
    }

    // update form data and config in redux
    let errorCount = commonFunction.getCountOfErrorsFromForm(namespace, formConfigData.sections[pageNumber]);
    form_data[formConfigData.sections[pageNumber].id].errorCount = errorCount;

    store.dispatch(updateFormData(namespace, { ...form_data }));
    store.dispatch(getFormConfigData(namespace, { ...formConfigData }));
  }

  static callDefaultActionFunction(componentKey, resetValue, itemShow, form_data, formConfigData) {
    // set defaultAction
    let showArray = [],
      hideArray = [],
      resetArray = [],
      closeFlag = false;
    for (let formsIndex = 0; formsIndex < formConfigData.sections.length; formsIndex++) {
      if (closeFlag) break;
      const componentsArray = formConfigData.sections[formsIndex].components;
      for (let index = 0; index < componentsArray.length; index++) {
        const formArray = componentsArray[index].elements;
        closeFlag = commonFunction.findComponentByIdDAFunctions(
          componentKey,
          formArray,
          showArray,
          hideArray,
          resetArray,
          formConfigData.sections[formsIndex].id,
          itemShow,
          resetValue,
          form_data,
          0
        );
        if (closeFlag) break;
      }
    }

    for (let formsIndex = 0; formsIndex < formConfigData.sections.length; formsIndex++) {
      const componentsArray = formConfigData.sections[formsIndex].components;

      for (let index = 0; index < componentsArray.length; index++) {
        const formArray = componentsArray[index].elements;
        for (let j = 0; j < hideArray.length; j++) {
          const element = hideArray[j];
          commonFunction.findComponentByIDSHOWFIELDFunction(
            element,
            formArray,
            formConfigData.sections[formsIndex].id,
            false,
            0
          );
          commonFunction.findComponentByIDRESETDATAFunction(
            element,
            formArray,
            formConfigData.sections[formsIndex].id,
            form_data,
            0
          );
        }
      }
    }
    for (let formsIndex = 0; formsIndex < formConfigData.sections.length; formsIndex++) {
      const componentsArray = formConfigData.sections[formsIndex].components;

      for (let index = 0; index < componentsArray.length; index++) {
        const formArray = componentsArray[index].elements;
        for (let j = 0; j < showArray.length; j++) {
          const element = showArray[j];
          commonFunction.findComponentByIDSHOWFIELDFunction(
            element,
            formArray,
            formConfigData.sections[formsIndex].id,
            false,
            0
          );
          commonFunction.findComponentByIDRESETDATAFunction(
            element,
            formArray,
            formConfigData.sections[formsIndex].id,
            form_data,
            0
          );
        }
      }
    }
    for (let formsIndex = 0; formsIndex < formConfigData.sections.length; formsIndex++) {
      const componentsArray = formConfigData.sections[formsIndex].components;

      for (let index = 0; index < componentsArray.length; index++) {
        const formArray = componentsArray[index].elements;
        for (let j = 0; j < resetArray.length; j++) {
          const element = resetArray[j];
          commonFunction.findComponentByIDRESETDATAFunction(
            element,
            formArray,
            formConfigData.sections[formsIndex].id,
            form_data,
            0
          );
        }
      }
    }

    return form_data;
  }

  static findComponentByIdDAFunctions(
    componentKey,
    formArray,
    showArray,
    hideArray,
    resetArray,
    path,
    itemShow,
    resetValue,
    form_data,
    callMeTime
  ) {
    if (callMeTime > 1) return;

    let returnFlag = false;
    for (let formIndex = 0; formIndex < formArray.length; formIndex++) {
      if (formArray[formIndex].element_type === "empty") continue;
      let item = formArray[formIndex].field;
      let myPath = path + ":" + item.id;
      if (item.id === componentKey) {
        if (item?.defaultAction?.action) {
          let actionsArray = item?.defaultAction?.action || [];
          for (let actionIndex = 0; actionIndex < actionsArray.length; actionIndex++) {
            const actionObj = actionsArray[actionIndex];

            if (actionObj.type === "set_options" || actionObj.type === "set_value") {
              if (hideArray.indexOf(actionObj.child_id) === -1) hideArray.push(actionObj.child_id);
            } else if (actionObj.type === "show") {
              for (let index = 0; index < actionObj.child_id.length; index++) {
                const element = actionObj.child_id[index];
                if (showArray.indexOf(element) === -1) showArray.push(element);
              }
            } else if (actionObj.type === "reset") {
              for (let index = 0; index < actionObj.child_id.length; index++) {
                const element = actionObj.child_id[index];
                if (resetArray.indexOf(element) === -1) resetArray.push(element);
              }
            } else if (actionObj.type === "hide") {
              for (let index = 0; index < actionObj.child_id.length; index++) {
                const element = actionObj.child_id[index];
                if (hideArray.indexOf(element) === -1) hideArray.push(element);
              }
            }
          }
        }

        returnFlag = true;
        break;
      }
      if (["recordset", "recordset-list", "container", "grid", "rich-grid"].includes(item.type)) {
        returnFlag = commonFunction.findComponentByIdDAFunctions(
          componentKey,
          item.components,
          showArray,
          hideArray,
          resetArray,
          myPath,
          itemShow,
          resetValue,
          form_data,
          ++callMeTime
        );
      }
    }
    return returnFlag;
  }

  static findComponentByIDSHOWFIELDFunction(componentKey, formArray, path, itemShow, callMeTime) {
    if (callMeTime > 1) return;

    for (let formIndex = 0; formIndex < formArray.length; formIndex++) {
      if (formArray[formIndex].element_type === "empty") continue;
      let item = formArray[formIndex].field;
      let myPath = path + ":" + item.id;
      if (item.id === componentKey) {
        item.show = itemShow;
        return;
      }
      if (["recordset", "recordset-list", "container", "grid", "rich-grid"].includes(item.type)) {
        commonFunction.findComponentByIDSHOWFIELDFunction(
          componentKey,
          item.components,
          myPath,
          itemShow,
          ++callMeTime
        );
      }
    }
    return;
  }

  static findComponentByIDRESETDATAFunction(componentKey, formArray, path, form_data, callMeTime) {
    if (callMeTime > 1) return;

    for (let formIndex = 0; formIndex < formArray.length; formIndex++) {
      if (formArray[formIndex].element_type === "empty") continue;
      let item = formArray[formIndex].field;
      let myPath = path + ":" + item.id;
      if (item.id === componentKey) {
        let dataObject = form_data;
        let keyArray = myPath.split(":");
        let index = 0;
        while (index < keyArray.length) {
          const key = keyArray[index];
          dataObject = dataObject[key];
          if (!dataObject) break;
          index++;
        }

        if (dataObject) {
          let valueA;
          if (item.type === "checkbox" || item.type === "toggle") valueA = false;
          else if (item.type === "selectboxes") valueA = {};
          else if (item.type === "file" || item.type === "image") valueA = [];
          else valueA = "";

          if (["recordset", "container", "recordset-list", "grid", "rich-grid"].includes(item.type)) {
            let dataObject = form_data;
            let keyArray = myPath.split(":");
            let index = 0;
            while (index < keyArray.length - 1) {
              const key = keyArray[index];
              dataObject = dataObject[key];
              if (!dataObject) break;
              index++;
            }
            dataObject[keyArray[keyArray.length - 1]] = { errors: [] };
          } else {
            dataObject.value = valueA;
            dataObject.errors = [];
          }
        }

        if (
          item.type === "select" ||
          item.type === "selectboxes" ||
          item.type === "radio" ||
          item.type === "number-select"
        ) {
          item.data.values = item.data.defaultValues;
        }

        return;
      }
      if (["recordset", "recordset-list", "container", "grid", "rich-grid"].includes(item.type)) {
        commonFunction.findComponentByIDRESETDATAFunction(
          componentKey,
          item.components,
          myPath,
          form_data,
          ++callMeTime
        );
      }
    }
    return;
  }

  static getCountOfErrorsFromForm(namespace, formArray) {
    let formKeysArray = [];
    commonFunction.getCompleteKeysFromForm(formKeysArray, formArray.components, formArray.id);
    let formData = store.getState()?.["formviewstore"]?.[`${namespace}_formData`];
    let errorCount = 0;
    for (let keysIndex = 0; keysIndex < formKeysArray.length; keysIndex++) {
      let formDataObject = formData;
      const keyArray = formKeysArray[keysIndex].split(":");
      for (let keyIndex = 0; keyIndex < keyArray.length; keyIndex++) {
        const key = keyArray[keyIndex];
        if (formDataObject?.[key]) formDataObject = formDataObject[key];
        else formDataObject = undefined;
      }
      if (formDataObject) {
        errorCount += formDataObject?.errors?.length || 0;
      }
    }

    return errorCount;
  }

  static getKeyCompleteDataFromForm(namespace, keyName) {
    let formsArray = store.getState()?.["formviewstore"]?.[`${namespace}_formConfigData`].sections;
    let formData = JSON.parse(JSON.stringify(store.getState()?.["formviewstore"]?.[`${namespace}_formData`]));
    let dateKeysArray = store.getState()?.["formviewstore"]?.[`${namespace}_formFieldKeyAndType`];

    let recordset_list_flagObject = {};
    return commonFunction.callGetKeyCompleteDataFromForm(
      namespace,
      keyName,
      formsArray,
      formData,
      dateKeysArray,
      recordset_list_flagObject
    );
  }

  static callGetKeyCompleteDataFromForm(
    namespace,
    keyName,
    formsArray,
    formData,
    dateKeysArray,
    recordset_list_flagObject
  ) {
    let sendDataBeck = {};
    let formKeysArray = [];
    for (let formsIndex = 0; formsIndex < formsArray.length; formsIndex++) {
      const formArray = formsArray[formsIndex].components;
      let path = formsArray[formsIndex].id;
      commonFunction.getCompleteKeysFromForm(formKeysArray, formArray, path);
    }

    for (let keysIndex = 0; keysIndex < formKeysArray.length; keysIndex++) {
      let formDataObject = formData;
      let formDataObjectSend = sendDataBeck;
      const keyArray = formKeysArray[keysIndex].split(":");
      const lastKey = keyArray[keyArray.length - 1];

      let flag = true;
      for (let keyIndex = 0; keyIndex < keyArray.length - 1; keyIndex++) {
        const key = keyArray[keyIndex];
        if (!formDataObjectSend[key]) {
          formDataObjectSend[key] = {};
        }
        formDataObjectSend = formDataObjectSend[key];
        formDataObject = formDataObject[key];
        if (!formDataObject) {
          flag = false;
          continue;
        }
      }

      if (dateKeysArray.recordset_list[lastKey]) {
        let recordset_list = dateKeysArray.recordset_list[lastKey];
        if (flag && formDataObject[lastKey]) {
          let dataArray = recordset_list?.level_fields || [];
          let fDataArray = {};
          for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            if (["errors", "value"].includes(element)) continue;
            let value = formDataObject?.[lastKey]?.[element]?.[keyName] || null;
            if (typeof value !== "undefined" && value !== null && value !== "") {
              fDataArray[element] = value;
            }
          }
          let array = [...(formDataObject[lastKey + "_recordset_list"] || [])];
          recordset_list_flagObject[lastKey] = array.length;
          for (let index = 0; index < array.length; index++) {
            let obj = {};
            let fdata = array[index];
            for (let index = 0; index < dataArray.length; index++) {
              const element = dataArray[index];
              if (["errors", "value"].includes(element)) continue;
              obj[element] = fdata?.[element]?.[keyName];
            }
            array[index] = obj;
          }
          if (Object.keys(fDataArray).length > 0) {
            array.push(fDataArray);
          }
          formDataObjectSend[lastKey] = array;
        } else {
          formDataObjectSend[lastKey] = formDataObject[lastKey + "_recordset_list"] || [];
        }
      } else if (dateKeysArray.grid[lastKey]) {
        let grid = dateKeysArray.grid[lastKey];
        if (flag && formDataObject[lastKey]) {
          let dataArray = grid?.level_fields || [];
          let fDataArray = {};
          for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            if (["errors", "value"].includes(element)) continue;
            let value = formDataObject?.[lastKey]?.[element]?.[keyName] || null;
            if (typeof value !== "undefined" && value !== null && value !== "") {
              fDataArray[element] = value;
            }
          }
          let array = [...(formDataObject[lastKey + "_grid"] || [])];
          recordset_list_flagObject[lastKey] = array.length;
          for (let index = 0; index < array.length; index++) {
            let obj = {};
            let fdata = array[index];
            for (let index = 0; index < dataArray.length; index++) {
              const element = dataArray[index];
              if (["errors", "value"].includes(element)) continue;
              obj[element] = fdata?.[element]?.[keyName];
            }
            array[index] = obj;
          }
          if (Object.keys(fDataArray).length > 0) {
            array.push(fDataArray);
          }
          formDataObjectSend[lastKey] = array;
        } else {
          formDataObjectSend[lastKey] = formDataObject[lastKey + "_grid"] || [];
        }
      } else if (dateKeysArray.rich_grid[lastKey]) {
        let rich_grid = dateKeysArray.rich_grid[lastKey];
        if (flag && formDataObject[lastKey]) {
          let dataArray = rich_grid?.level_fields || [];
          let fDataArray = {};
          for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            if (["errors", "value"].includes(element)) continue;
            let value = formDataObject?.[lastKey]?.[element]?.[keyName] || null;
            if (typeof value !== "undefined" && value !== null && value !== "") {
              fDataArray[element] = value;
            }
          }
          let array = [...(formDataObject[lastKey + "_rich_grid"] || [])];
          recordset_list_flagObject[lastKey] = array.length;
          for (let index = 0; index < array.length; index++) {
            let obj = {};
            let fdata = array[index];
            for (let index = 0; index < dataArray.length; index++) {
              const element = dataArray[index];
              if (["errors", "value"].includes(element)) continue;
              obj[element] = fdata?.[element]?.[keyName];
            }
            array[index] = obj;
          }
          if (Object.keys(fDataArray).length > 0) {
            array.push(fDataArray);
          }
          formDataObjectSend[lastKey] = array;
        } else {
          formDataObjectSend[lastKey] = formDataObject[lastKey + "_rich_grid"] || [];
        }
      } else if (dateKeysArray.recordset[lastKey]) {
        if (flag && formDataObject[lastKey]) {
          let dataArray = Object.keys(formDataObject[lastKey]);
          let fDataArray = {};
          for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            if (element === "errors") continue;
            fDataArray[element] = formDataObject[lastKey][element][keyName];
          }
          formDataObjectSend[lastKey] = fDataArray;
        }
      } else if (dateKeysArray.container[lastKey]) {
        if (flag && formDataObject[lastKey]) {
          let dataArray = Object.keys(formDataObject[lastKey]);
          let fDataArray = {};
          for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            if (element === "errors") continue;
            fDataArray[element] = formDataObject[lastKey][element][keyName];
          }
          formDataObjectSend[lastKey] = fDataArray;
        }
      } else {
        if (flag && formDataObject[lastKey]) {
          formDataObjectSend[lastKey] = formDataObject[lastKey][keyName];
        }
      }
    }
    return sendDataBeck;
  }

  static getKeyCompleteErrorDataFromForm(namespace) {
    let formsArray = store.getState()?.["formviewstore"]?.[`${namespace}_formConfigData`].sections;
    let formData = JSON.parse(JSON.stringify(store.getState()?.["formviewstore"]?.[`${namespace}_formData`]));

    return commonFunction.callGetKeyCompleteErrorDataFromForm(
      namespace,
      formsArray,
      JSON.parse(JSON.stringify(formData || {}))
    );
  }

  static callGetKeyCompleteErrorDataFromForm(namespace, formsArray, form_data) {
    let errorObject = {};
    for (let formsIndex = 0; formsIndex < formsArray.length; formsIndex++) {
      const formSections = formsArray[formsIndex].components;
      let path = formsArray[formsIndex].id;
      for (let index = 0; index < formSections.length; index++) {
        const formArray = formSections[index].elements;
        let errorObject1 = commonFunction.callGetKeyCompleteErrorDataFromForm1(namespace, formArray, path, form_data);
        errorObject[path] = errorObject1;
      }
    }

    return errorObject;
  }
  static callGetKeyCompleteErrorDataFromForm1(namespace, formArray, path, form_data) {
    let errorObject = {};
    for (let formIndex = 0; formIndex < formArray.length; formIndex++) {
      if (["spacer", "empty", "button", "lable"].includes(formArray[formIndex].element_type)) continue;
      const item = formArray[formIndex].field;
      if (["container", "recordset"].includes(item.type)) {
        const myPath = path + ":" + item.id;
        let errorObject1 = commonFunction.callGetKeyCompleteErrorDataFromForm1(
          namespace,
          item.components,
          myPath,
          form_data
        );
        errorObject[item.id] = errorObject1;
      } else if (["recordset-list"].includes(item.type)) {
        let errorObjectArray = [];
        const myPath1 = path + ":" + item.id + "_recordset_list";
        let dataObject1 = form_data;
        let keyArray1 = myPath1.split(":");
        let index1 = 0;
        while (index1 < keyArray1.length) {
          const key = keyArray1[index1];
          // add object in form data
          if (!dataObject1[key]) dataObject1[key] = { errors: [] };

          dataObject1 = dataObject1[key];
          index1++;
        }
        if (!dataObject1) {
          dataObject1 = [];
        }
        if (Array.isArray(dataObject1)) {
          for (let index = 0; index < dataObject1.length; index++) {
            const myPath2 = path + ":" + item.id + "_recordset_list:" + index;
            let errorObject1 = commonFunction.callGetKeyCompleteErrorDataFromForm1(
              namespace,
              item.components,
              myPath2,
              form_data
            );
            errorObjectArray.push(errorObject1);
          }
        }

        const myPath = path + ":" + item.id;
        let dataObject = form_data;
        let keyArray = myPath.split(":");
        let index = 0;
        while (index < keyArray.length) {
          const key = keyArray[index];
          // add object in form data
          if (!dataObject[key]) dataObject[key] = { errors: [] };

          dataObject = dataObject[key];
          index++;
        }
        if (Object.keys(dataObject).length > 1) {
          let errorObject1 = commonFunction.callGetKeyCompleteErrorDataFromForm1(
            namespace,
            item.components,
            myPath,
            form_data
          );
          errorObjectArray.push(errorObject1);
        }

        errorObject[item.id] = errorObjectArray;
      } else if (["grid"].includes(item.type)) {
        let errorObjectArray = [];
        const myPath1 = path + ":" + item.id + "_grid";
        let dataObject1 = form_data;
        let keyArray1 = myPath1.split(":");
        let index1 = 0;
        while (index1 < keyArray1.length) {
          const key = keyArray1[index1];
          // add object in form data
          if (!dataObject1[key]) dataObject1[key] = { errors: [] };

          dataObject1 = dataObject1[key];
          index1++;
        }
        if (!dataObject1) {
          dataObject1 = [];
        }
        if (Array.isArray(dataObject1)) {
          for (let index = 0; index < dataObject1.length; index++) {
            const myPath2 = path + ":" + item.id + "_grid:" + index;
            let errorObject1 = commonFunction.callGetKeyCompleteErrorDataFromForm1(
              namespace,
              item.components,
              myPath2,
              form_data
            );
            errorObjectArray.push(errorObject1);
          }
        }

        const myPath = path + ":" + item.id;
        let dataObject = form_data;
        let keyArray = myPath.split(":");
        let index = 0;
        while (index < keyArray.length) {
          const key = keyArray[index];
          // add object in form data
          if (!dataObject[key]) dataObject[key] = { errors: [] };

          dataObject = dataObject[key];
          index++;
        }
        if (Object.keys(dataObject).length > 1) {
          let errorObject1 = commonFunction.callGetKeyCompleteErrorDataFromForm1(
            namespace,
            item.components,
            myPath,
            form_data
          );
          errorObjectArray.push(errorObject1);
        }

        errorObject[item.id] = errorObjectArray;
      } else if (["rich-grid"].includes(item.type)) {
        let errorObjectArray = [];
        const myPath1 = path + ":" + item.id + "_rich_grid";
        let dataObject1 = form_data;
        let keyArray1 = myPath1.split(":");
        let index1 = 0;
        while (index1 < keyArray1.length) {
          const key = keyArray1[index1];
          // add object in form data
          if (!dataObject1[key]) dataObject1[key] = { errors: [] };

          dataObject1 = dataObject1[key];
          index1++;
        }
        if (!dataObject1) {
          dataObject1 = [];
        }
        if (Array.isArray(dataObject1)) {
          for (let index = 0; index < dataObject1.length; index++) {
            const myPath2 = path + ":" + item.id + "_rich_grid:" + index;
            let errorObject1 = commonFunction.callGetKeyCompleteErrorDataFromForm1(
              namespace,
              item.components,
              myPath2,
              form_data
            );
            errorObjectArray.push(errorObject1);
          }
        }

        const myPath = path + ":" + item.id;
        let dataObject = form_data;
        let keyArray = myPath.split(":");
        let index = 0;
        while (index < keyArray.length) {
          const key = keyArray[index];
          // add object in form data
          if (!dataObject[key]) dataObject[key] = { errors: [] };

          dataObject = dataObject[key];
          index++;
        }
        if (Object.keys(dataObject).length > 1) {
          let errorObject1 = commonFunction.callGetKeyCompleteErrorDataFromForm1(
            namespace,
            item.components,
            myPath,
            form_data
          );
          errorObjectArray.push(errorObject1);
        }

        errorObject[item.id] = errorObjectArray;
      } else {
        const myPath = path + ":" + item.id;
        let dataObject = form_data;
        let keyArray = myPath.split(":");
        let index = 0;
        while (index < keyArray.length) {
          const key = keyArray[index];
          // add object in form data
          if (!dataObject[key]) dataObject[key] = { errors: [] };

          dataObject = dataObject[key];
          index++;
        }
        let errorsArray = dataObject?.errors || [];
        errorObject[item.id] = errorsArray;
      }
    }

    return errorObject;
  }

  static updateDataByKeyInObject(key, resData, data) {
    let dataObject = resData;
    let keyArray = key.split(":");
    let lastKey = keyArray[keyArray.length - 1];
    let index = 0;
    while (index < keyArray.length - 1) {
      const key = keyArray[index];
      // add object in form data
      if (isNaN(key)) {
        if (!dataObject?.[key]) {
          dataObject[key] = {};
        }
      } else {
        let intN = parseInt(key);
        if (!dataObject?.[key]) {
          if (intN > 0) {
            dataObject[key] = [{}];
          } else {
            dataObject[key] = [...dataObject[key], {}];
          }
        }
      }

      dataObject = dataObject[key];
      index++;
    }
    dataObject[lastKey] = data;
  }

  static getDataByKeyInObject(key, resData) {
    let dataObject = resData;
    let keyArray = key.split(":");
    let lastKey = keyArray[keyArray.length - 1];
    let index = 0;
    while (index < keyArray.length - 1) {
      const key = keyArray[index];
      // add object in form data
      if (!dataObject[key]) {
        dataObject[key] = {};
      }

      dataObject = dataObject[key];
      index++;
    }
    return dataObject[lastKey];
  }

  static getCompleteKeysFromForm(formKeysArray, componentsArray, path, flag = true) {
    for (let index = 0; index < componentsArray.length; index++) {
      const formArray = componentsArray[index].elements;
      commonFunction.getCompleteKeysFromFormCall(formKeysArray, formArray, path, flag);
    }
  }

  static getCompleteKeysFromFormCall(formKeysArray, formArray, path, flag = true) {
    for (let formIndex = 0; formIndex < formArray.length; formIndex++) {
      if (["empty"].includes(formArray[formIndex].element_type)) continue;
      const item = formArray[formIndex].field;
      if (item.show || flag) {
        const myPath = path + ":" + item.id;
        formKeysArray.push(myPath);
      } else if (["app-user"].includes(item.type) && item.prefill_current_user) {
        const myPath = path + ":" + item.id;
        formKeysArray.push(myPath);
      } else if (["date", "time", "day", "datetime", "multi-date"].includes(item.type) && item.prefill_current) {
        const myPath = path + ":" + item.id;
        formKeysArray.push(myPath);
      }
    }
  }

  static async callCheckCompleteFormSection(namespace, formSections, path) {
    for (let index = 0; index < formSections.length; index++) {
      const formArray = formSections[index].elements;
      await commonFunction.callCheckCompleteFormSection1(namespace, formArray, path);
    }
  }
  static async callCheckCompleteFormSection1(namespace, formArray, path) {
    let form_data = store.getState()?.["formviewstore"]?.[`${namespace}_formData`] || {};
    for (let formIndex = 0; formIndex < formArray.length; formIndex++) {
      if (["spacer", "empty", "button", "lable"].includes(formArray[formIndex].element_type)) continue;
      const item = formArray[formIndex].field;
      if (item.show || item?.validate?.mandatory) {
        if (["container", "recordset"].includes(item.type)) {
          const myPath = path + ":" + item.id;
          await commonFunction.callCheckCompleteFormSection1(namespace, item.components, myPath);
        } else if (["recordset-list"].includes(item.type)) {
          const myPath1 = path + ":" + item.id + "_recordset_list";
          let dataObject1 = form_data;
          let keyArray1 = myPath1.split(":");
          let index1 = 0;
          while (index1 < keyArray1.length) {
            const key = keyArray1[index1];
            // add object in form data
            if (!dataObject1[key]) dataObject1[key] = { errors: [] };

            dataObject1 = dataObject1[key];
            index1++;
          }
          if (!dataObject1) {
            dataObject1 = [];
          }
          if (Array.isArray(dataObject1)) {
            for (let index = 0; index < dataObject1.length; index++) {
              const myPath2 = path + ":" + item.id + "_recordset_list:" + index;
              await commonFunction.callCheckCompleteFormSection1(namespace, item.components, myPath2);
            }
          }

          const myPath = path + ":" + item.id;
          let dataObject = form_data;
          let keyArray = myPath.split(":");
          let index = 0;
          while (index < keyArray.length) {
            const key = keyArray[index];
            // add object in form data
            if (!dataObject[key]) dataObject[key] = { errors: [] };

            dataObject = dataObject[key];
            index++;
          }
          if (Object.keys(dataObject || {}).length > 1 || dataObject1.length === 0) {
            await commonFunction.callCheckCompleteFormSection1(namespace, item.components, myPath);
          }
        } else if (["grid"].includes(item.type)) {
          const myPath1 = path + ":" + item.id + "_grid";
          let dataObject1 = form_data;
          let keyArray1 = myPath1.split(":");
          let index1 = 0;
          while (index1 < keyArray1.length) {
            const key = keyArray1[index1];
            // add object in form data
            if (!dataObject1[key]) dataObject1[key] = { errors: [] };

            dataObject1 = dataObject1[key];
            index1++;
          }
          if (!dataObject1) {
            dataObject1 = [];
          }
          if (Array.isArray(dataObject1)) {
            for (let index = 0; index < dataObject1.length; index++) {
              const myPath2 = path + ":" + item.id + "_grid:" + index;
              await commonFunction.callCheckCompleteFormSection1(namespace, item.components, myPath2);
            }
          }
        } else if (["rich-grid"].includes(item.type)) {
          const myPath1 = path + ":" + item.id + "_rich_grid";
          let dataObject1 = form_data;
          let keyArray1 = myPath1.split(":");
          let index1 = 0;
          while (index1 < keyArray1.length) {
            const key = keyArray1[index1];
            // add object in form data
            if (!dataObject1[key]) dataObject1[key] = { errors: [] };

            dataObject1 = dataObject1[key];
            index1++;
          }
          if (!dataObject1) {
            dataObject1 = [];
          }
          if (Array.isArray(dataObject1)) {
            for (let index = 0; index < dataObject1.length; index++) {
              const myPath2 = path + ":" + item.id + "_rich_grid:" + index;
              await commonFunction.callCheckCompleteFormSection1(namespace, item.components, myPath2);
            }
          }
        } else {
          const myPath = path + ":" + item.id;
          let dataObject = form_data;
          let keyArray = myPath.split(":");
          let index = 0;
          while (index < keyArray.length) {
            const key = keyArray[index];
            // add object in form data
            if (!dataObject[key]) dataObject[key] = { errors: [] };

            dataObject = dataObject[key];
            index++;
          }
          let value = dataObject?.value;
          if (value === undefined) {
            if (item.type === "checkbox" || item.type === "toggle") value = false;
            else if (item.type === "selectboxes") value = {};
            else if (item.type === "file" || item.type === "image") value = [];
            else if (item.type === "grid") value = [];
            else value = "";
          }
          if (item.type === "file" || item.type === "image") value = { value: value, errors: [] };
          await commonFunction.callChackFiledFunction(namespace, value, myPath, item, false);
        }
      }
    }
  }

  static getOnlyDataFromObject(fullData, namespace, onsubmit) {
    let dateKeysArray = store.getState()?.["formviewstore"]?.[`${namespace}_formFieldKeyAndType`];
    return commonFunction.callGetOnlyDataFromObject(namespace, fullData, dateKeysArray, onsubmit);
  }

  static callGetOnlyDataFromObject(namespace, fullData, dateKeysArray, onsubmit) {
    let dataKeyArray = Object.keys(fullData);
    for (let index = 0; index < dataKeyArray.length; index++) {
      const element = dataKeyArray[index];
      if (typeof fullData[element] === "object") {
        fullData = { ...fullData, ...fullData[element] };
        delete fullData[element];
      }
    }
    dataKeyArray = Object.keys(fullData);
    for (let index = 0; index < dataKeyArray.length; index++) {
      const element = dataKeyArray[index];
      if (!fullData[element] && fullData[element] !== false) {
        delete fullData[element];
      }
    }

    return changeDataTypeStringToAny(dateKeysArray, fullData, onsubmit);
  }

  static async uploadImageInDatabase(namespace) {
    let keyObject = store.getState()?.["formviewstore"]?.[`${namespace}_formFieldKeyAndType`] || {};
    let form_data = store.getState()?.["formviewstore"]?.[`${namespace}_formData`] || {};

    let formData = form_data;
    keyObject["id"] = "Section_123";
    keyObject["type"] = "section";
    let errorFlag = await commonFunction.rsuploadImageInDatabase(namespace, formData, keyObject);

    store.dispatch(updateFormData(namespace, { ...form_data }));
    return errorFlag;
  }

  static async rsuploadImageInDatabase(namespace, fullData, keyObject) {
    let flag = true;
    let fileKeysArray = keyObject?.["file"] || [];
    let formData = fullData;
    if (keyObject.type === "recordset-list") {
      let extraData = formData[keyObject.id + "_recordset_list"] || [];
      for (let index = 0; index < extraData.length; index++) {
        let newData = extraData[index];
        for (let index = 0; index < fileKeysArray.length; index++) {
          const filekey = fileKeysArray[index];
          let imageArray = newData?.[filekey]?.value || [];
          if (imageArray.length > 0) {
            let notOk = await commonFunction.uploadImageFunction(imageArray);
            if (notOk) {
              return false;
            }
          }
        }
      }
    } else if (keyObject.type === "grid") {
      let extraData = formData[keyObject.id + "_grid"] || [];
      for (let index = 0; index < extraData.length; index++) {
        let newData = extraData[index];
        for (let index = 0; index < fileKeysArray.length; index++) {
          const filekey = fileKeysArray[index];
          let imageArray = newData?.[filekey]?.value || [];
          if (imageArray.length > 0) {
            let notOk = await commonFunction.uploadImageFunction(imageArray);
            if (notOk) {
              return false;
            }
          }
        }
      }
    } else if (keyObject.type === "rich-grid") {
      let extraData = formData[keyObject.id + "_rich_grid"] || [];
      for (let index = 0; index < extraData.length; index++) {
        let newData = extraData[index];
        for (let index = 0; index < fileKeysArray.length; index++) {
          const filekey = fileKeysArray[index];
          let imageArray = newData?.[filekey]?.value || [];
          if (imageArray.length > 0) {
            let notOk = await commonFunction.uploadImageFunction(imageArray);
            if (notOk) {
              return false;
            }
          }
        }
      }
    }

    formData = formData[keyObject.id];
    if (!formData) return true;

    for (let index = 0; index < fileKeysArray.length; index++) {
      const filekey = fileKeysArray[index];
      let imageArray = formData?.[filekey]?.value || [];
      if (imageArray.length > 0) {
        let notOk = await commonFunction.uploadImageFunction(imageArray);
        if (notOk) {
          return false;
        }
      }
    }

    if (flag) {
      let rsfileKeysArray = keyObject?.["recordset"] || {};
      rsfileKeysArray = Object.values(rsfileKeysArray);
      for (let index = 0; index < rsfileKeysArray.length; index++) {
        const element = rsfileKeysArray[index];
        flag = await commonFunction.rsuploadImageInDatabase(namespace, formData, element);
      }
    }

    if (flag) {
      let rsfileKeysArray = keyObject?.["recordset_list"] || {};
      rsfileKeysArray = Object.values(rsfileKeysArray);
      for (let index = 0; index < rsfileKeysArray.length; index++) {
        const element = rsfileKeysArray[index];
        flag = await commonFunction.rsuploadImageInDatabase(namespace, formData, element);
      }
    }

    if (flag) {
      let rsfileKeysArray = keyObject?.["container"] || {};
      rsfileKeysArray = Object.values(rsfileKeysArray);
      for (let index = 0; index < rsfileKeysArray.length; index++) {
        const element = rsfileKeysArray[index];
        flag = await commonFunction.rsuploadImageInDatabase(namespace, formData, element);
      }
    }
    if (flag) {
      let rsfileKeysArray = keyObject?.["grid"] || {};
      rsfileKeysArray = Object.values(rsfileKeysArray);
      for (let index = 0; index < rsfileKeysArray.length; index++) {
        const element = rsfileKeysArray[index];
        flag = await commonFunction.rsuploadImageInDatabase(namespace, formData, element);
      }
    }
    if (flag) {
      let rsfileKeysArray = keyObject?.["rich_grid"] || {};
      rsfileKeysArray = Object.values(rsfileKeysArray);
      for (let index = 0; index < rsfileKeysArray.length; index++) {
        const element = rsfileKeysArray[index];
        flag = await commonFunction.rsuploadImageInDatabase(namespace, formData, element);
      }
    }
    return flag;
  }

  static async uploadImageFunction(element) {
    for (let index = element.length - 1; index >= 0; ) {
      if (element[index]["url"]) {
        if (
          element[index]["url"].includes(getApiCallLocalPath() + "api/v1/assets/fileName/") &&
          element[index]["name"]
        ) {
          // element[index] = element[index]["url"].split("api/v1/assets/fileName/")[1];
        } else {
          const ele = element[index]["url"];
          const id = element[index]["id"] || 0;
          const form = new FormData();
          if (ele === "true" && id === 0) {
            if (element[index]["file"]["name"]) {
              form.append("myFile", element[index]["file"], element[index]["file_name"]);
            } else if (form_images_store[element[index]["file_id"]]) {
              const imageData = await fetch(form_images_store[element[index]["file_id"]]);
              const blob = await imageData.blob();
              form.append("myFile", blob, element[index]["file_name"]);
            } else {
              index--;
              continue;
              // toast.error(`{ ${element[index]["file_name"]} }file not upload`, toastErrorMessageStyle());
              // return false;
            }
          } else if (id === 0) {
            const imageData = await fetch(ele);
            const blob = await imageData.blob();
            form.append("myFile", blob, element[index]["file_name"]);
          }
          const options =
            id !== 0
              ? {
                  method: "POST",
                  headers: getApiCallHeadersData(),
                  url: `${getApiCallLocalPath()}api/v1/sync/cabinet/file/default/${id}`
                }
              : {
                  method: "POST",
                  headers: getApiCallHeadersData({
                    "Content-Type": "multipart/form-data; boundary=---011000010111000001101001"
                  }),
                  url: `${getApiCallLocalPath()}api/v1/uploadfile`,
                  data: form
                };
          let flag = false;
          await axios
            .request(options)
            .then(function (response) {
              element[index] = response.data.data;
            })
            .catch(function (error) {
              flag = true;
              toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
            });
          if (flag) {
            return true;
          }
        }
        index--;
      } else {
        index--;
      }
    }
  }

  // checking current form required fileds and show errors and updating data in redux
  static checkCurrentForm = async (namespace, formPageNumber) => {
    let formData = store.getState()?.["formviewstore"]?.[`${namespace}_formData`] || {};
    let config = store.getState()?.["formviewstore"]?.[`${namespace}_formConfigData`];
    let formErrorColor = store.getState()?.["formviewstore"]?.[`${namespace}_formErrorColor`];
    let formsArray = config.sections;
    const formArray = formsArray[formPageNumber];

    await commonFunction.callCheckCompleteFormSection(namespace, formArray.components, formArray.id);
    let errorColor;
    if (config.sections[formPageNumber].components.length === 0 || formData[formArray.id]?.errorCount === 0) {
      errorColor = {
        ...formErrorColor,
        [formArray.id]: config.progress_bar_color_codes.filled_valid
      };
    } else {
      errorColor = {
        ...formErrorColor,
        [formArray.id]: config.progress_bar_color_codes.filled_invalid
      };
    }
    store.dispatch(setFormPageErrorColor(namespace, errorColor));
  };

  // checking all form required fileds and show errors and updating data in redux
  static checkAllForm = async (namespace) => {
    let config = store.getState()?.["formviewstore"]?.[`${namespace}_formConfigData`];
    let formErrorColor = store.getState()?.["formviewstore"]?.[`${namespace}_formErrorColor`];
    let formsArray = config.sections;
    for (let formsIndex = 0; formsIndex < formsArray.length; formsIndex++) {
      const formArray = formsArray[formsIndex].components;
      let path = formsArray[formsIndex].id;
      await commonFunction.callCheckCompleteFormSection(namespace, formArray, path);
    }

    let formData = store.getState()?.["formviewstore"]?.[`${namespace}_formData`] || {};

    let errorFlag = false;
    let errorColor = { ...formErrorColor };
    for (let index = 0; index < formsArray.length; index++) {
      const sectionKey = formsArray[index].id;
      if (formData[sectionKey]?.errorCount === 0) {
        errorColor[sectionKey] = config.progress_bar_color_codes.filled_valid;
      } else {
        errorFlag = true;
        errorColor[sectionKey] = config.progress_bar_color_codes.filled_invalid;
      }
    }
    store.dispatch(setFormPageErrorColor(namespace, errorColor));
    return errorFlag;
  };

  static async callCustomOnSubmitFunction(fullData, namespace) {
    try {
      let customJsFunction = store.getState()?.["formviewstore"]?.[`${namespace}_formOnSubmitFunction`];
      if (typeof customJsFunction === "string" && customJsFunction) {
        // eslint-disable-next-line no-new-func
        // const callMe = new Function("data", customJsFunction);
        // let res = callMe(fullData);
        let newFunWithData = `let data = ${JSON.stringify(fullData)}
        var user_token = ${JSON.stringify(getApiCallHeadersData()?.Authorization || "")}
        ${customJsFunction}`;

        if (
          customJsFunction.includes("http://") ||
          customJsFunction.includes("https://") ||
          customJsFunction.includes("getApiCallLocalPath()")
        ) {
          // eslint-disable-next-line no-eval
          let res = await eval(`(async () => { ${newFunWithData} })()`);
          return { form_data: res?.data || fullData, error: false };
        }

        // eslint-disable-next-line no-eval
        let res = eval(newFunWithData);
        return { form_data: res?.data || fullData, error: false };
      }
      return { form_data: fullData, error: false };
    } catch (error) {
      return { form_data: fullData, error: true };
    }
  }

  static resetCurrForm = (namespace) => {
    let formMasterData = store.getState()?.["formviewstore"]?.[`${namespace}_formMasterData`] || {};
    let formPreFillData = store.getState()?.["formviewstore"]?.[`${namespace}_formPreFillData`];
    let config = store.getState()?.["formviewstore"]?.[`${namespace}_formConfigData`];

    // update in redux
    let formFillData = formDataPrefill(formPreFillData || {}, {}, config, "reset", formMasterData);
    store.dispatch(updateFormData(namespace, formFillData));
  };
}

export const resetLocalStoreageFormData = (namespace, localStorageformkey, localStoragekey) => {
  const todayDate = new Date();
  let currTime = todayDate.getTime();

  let formSubmitTime = localStorage.getItem(localStoragekey);
  let diffTime = Math.floor((currTime - formSubmitTime) / 1000);
  if (diffTime / 60 > 15) {
    if (
      JSON.stringify({
        Section_123: {
          errorCount: 0
        }
      }) !== JSON.stringify(store.getState()?.["formviewstore"]?.[`${namespace}_formData`] || {})
    ) {
      store.dispatch(
        updateFormData(namespace, {
          Section_123: {
            errorCount: 0
          }
        })
      );
    }
    localStorage.removeItem(localStorageformkey);
    localStorage.removeItem(localStoragekey);
  }
};
