import React from "react";
import { gridCommonFunction } from "../../../utils/gridCommonFunction";
import FieldType from "./recordCatalogFields/FieldType";
import JobAction from "../actions/JobAction";
import DropdownAction from "../actions/DropdownAction";
import IconRender from "../../../../components/IconRender";
import { useSelector } from "react-redux";
import TableViewRenderer from "../../../tableviewlib";
import { stringToJSONChange } from "../../../utils/commonFunction";
import ProgressBar from "../../../widgetsLib/ProgressBar";

const RecordCatalogView = ({ elements = [], record = {}, namespace = "", parentType = "" }) => {
  let elementsArray = [];
  let gridArray = [];
  for (let i = 0; i < elements?.length; i++) {
    elementsArray.push(elements[i]?.element_config);
    gridArray.push(elements[i]?.grid);
  }
  let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(gridArray);
  let storeData = useSelector((state) => state.recordviewstore);
  let recordsviewLayoutConfig = storeData?.[`${namespace}_recordsviewLayoutConfig`];
  let table_id = recordsviewLayoutConfig?.layout?.table_id;

  return (
    <div className={parentType === "section" ? "" : "recordView_catalog"} style={styleDataForGrid}>
      {elementsArray?.map((element, index) => {
        let styleObject = {
          gridArea: `zino-${element?.i || element.id}`
        };
        let classNameList = Object.values(element?.className)?.join(" ");

        if (element?.hide === true) {
          return undefined;
        } else {
          switch (element.type) {
            case "field":
              return (
                <div style={{ ...styleObject }} key={index} className={`fieldContainer`}>
                  <FieldType element={element} record={record} namespace={namespace} />
                </div>
              );
            case "image":
              return (
                <div style={styleObject} key={index} className={`fieldContainer ${classNameList}`}>
                  {element?.icon && <IconRender iconValue={element?.icon} />}
                  <img
                    src={
                      element.repo_source
                        ? `${process.env.REACT_APP_IMAGE_HOST}/api/v1/public/${element.repo_source}`
                        : element?.source
                    }
                    alt="img"
                    style={{ width: "100%", objectFit: "cover" }}
                    className={`${classNameList}`}
                  />
                </div>
              );
            case "label":
              return (
                <div
                  style={{ ...styleObject, ...element?.inline_style }}
                  key={index}
                  className={`fieldContainer fieldDefaultClass ${classNameList}`}
                >
                  {element?.icon && <IconRender iconValue={element?.icon} />}
                  <p>{element?.name}</p>
                </div>
              );
            case "button":
              element.data_id = table_id;
              return (
                <div style={{ ...styleObject, paddingLeft: "4px" }} key={index}>
                  <JobAction
                    itemConfig={element}
                    itemType="single"
                    reqData={{ data: record, instance_id: record?.id }}
                  />
                </div>
              );
            case "dropdown":
              element.data_id = table_id;
              return (
                <div style={{ ...styleObject, paddingLeft: "4px" }} key={index}>
                  <DropdownAction
                    itemConfig={element}
                    itemType="single"
                    reqData={{ data: record, instance_id: record?.id }}
                  />
                </div>
              );
            case "spacer":
              return <div style={{ ...styleObject, height: `${element?.height}px` }} key={index}></div>;
            case "section":
              return (
                <div
                  style={{ ...styleObject, ...element?.inline_style, padding: "4px" }}
                  key={index}
                  className={`section_field_block  ${classNameList}`}
                >
                  <RecordCatalogView
                    elements={element?.config?.layouts}
                    record={record}
                    namespace={namespace}
                    parentType="section"
                  />
                </div>
              );
            case "separator":
              const value = stringToJSONChange(element?.custom_css);
              return <hr className="separator_field" style={{ ...styleObject, ...value }} key={index} />;
            case "html":
              return (
                <div
                  style={styleObject}
                  key={index}
                  className={`${classNameList}`}
                  dangerouslySetInnerHTML={{
                    __html: element?.content
                  }}
                ></div>
              );
            case "tableview":
              return (
                <div
                  style={{ ...styleObject, ...element?.inline_style, padding: "4px" }}
                  key={index}
                  className={`${classNameList}`}
                >
                  <TableViewRenderer dataConfig={element?.config} tableConfig={element} detailsViewData={record} />
                </div>
              );
            case "variable":
              return (
                <div
                  style={{ ...styleObject, ...element?.inline_style, padding: "4px" }}
                  key={index}
                  className={`${classNameList}`}
                >
                  <p>{record?.[element?.id] || "-"}</p>
                </div>
              );
            case "progress-tracker":
              return (
                <div
                  style={{ ...styleObject, ...element?.inline_style, ...stringToJSONChange(element?.custom_css || {}) }}
                  key={index}
                  className={`${classNameList}`}
                >
                  <ProgressBar itemConfig={element} data={record} />
                </div>
              );
            default:
              return (
                <div style={styleObject} key={index}>
                  {element.type}
                </div>
              );
          }
        }
      })}
    </div>
  );
};

export default RecordCatalogView;
