import React from "react";

import FormRendererFunction from "./FormRendererFunction";
import { store } from "../../../redux/store";
import { getFormConfig } from "../../../redux/formview/formviewaction";
import { resetLocalStoreageFormData } from "../utils/commonFunction";

function FormRenderer({ apidata = {}, callbackfunction = {}, namespace }) {
  if (apidata.saveformlocal === undefined) apidata.saveformlocal = false;
  if (apidata.apicallflag === undefined) apidata.apicallflag = false;
  if (apidata.inputapi === undefined) apidata.inputapi = "";
  if (apidata.apicalldata === undefined) apidata.apicalldata = null;

  if (callbackfunction.formsubmitgetresponscbfun === undefined) {
    callbackfunction.formsubmitgetresponscbfun = (res) => {};
  }

  if (!namespace) {
    return <p style={{ marginTop: "50px", fontSize: "16px", textAlign: "center" }}>Please add namespace key</p>;
  } else {
    resetLocalStoreageFormData(
      namespace,
      `${apidata?.apicalldata?.local_storage_key}`,
      `form-exp-time-${apidata?.apicalldata?.local_storage_key}`
    );
    store.dispatch(getFormConfig(namespace, apidata));
  }

  return (
    <>
      <FormRendererFunction callbackfunction={callbackfunction} namespace={namespace} apidata={apidata} />{" "}
    </>
  );
}

export default FormRenderer;
