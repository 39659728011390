import { FormLabel, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { commonFunction } from "../utils/commonFunction";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { toastErrorMessageStyle } from "../../../utils/apiCallFunction";

const FFGeoLocationField = ({ component, currentConfigKey, namespace }) => {
  // "granted" "prompt" "denied"
  const [permissionState, setPermissionState] = useState(null);
  useEffect(() => {
    const permissionName = "geolocation"; // Replace with the permission you want to monitor

    const handleChange = () => {
      navigator.permissions
        .query({ name: permissionName })
        .then((permissionStatus) => {
          setPermissionState(permissionStatus.state);
        })
        .catch((error) => {
          toast.error(`Error getting permission state: ${error}`, toastErrorMessageStyle());
        });
    };

    navigator.permissions
      .query({ name: permissionName })
      .then((permissionStatus) => {
        setPermissionState(permissionStatus.state);
        permissionStatus.onchange = handleChange;
      })
      .catch((error) => {
        toast.error(`Error getting initial permission state: ${error}`, toastErrorMessageStyle());
      });

    return () => {
      // Clean up the event listener when the component unmounts
      navigator.permissions.query({ name: permissionName }).then((permissionStatus) => {
        permissionStatus.onchange = null;
      });
    };
  }, []);

  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || ["", ""]
  );

  const [setinputValue, setsetInputValue] = useState();

  const setInputDataInState = (value, index) => {
    let number = parseInt(value);
    let valueArray = value.split(".");
    if (valueArray.length > 2 || valueArray?.[1]?.length > 15) {
      return;
    }
    if (index === 0) {
      if (number > 90 || number < -90) {
        return;
      }
    } else {
      if (number > 180 || number < -180) {
        return;
      }
    }
    let array = [...inputValue];
    array[index] = value;
    setInputValue(array);
  };

  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) {
      let value = commonFunction.getKeyValueFromForm(namespace, currentConfigKey);
      if (typeof value === "string") {
        value = value.split(",");
      }
      setInputValue(value || ["", ""]);
    } else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  useEffect(() => {
    if (permissionState === "denied" && (component.action === "auto" || component.background_run)) {
      toast.error(`Location permission is denied, Allow sites to see your location.`, toastErrorMessageStyle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionState, errors]);

  const funCall = async (inputValue1) => {
    await commonFunction.callChackFiledFunction(namespace, inputValue1, currentConfigKey, component);
  };

  useEffect(() => {
    if (JSON.stringify(inputValue) !== JSON.stringify(setinputValue)) {
      setsetInputValue(inputValue);
      funCall(inputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const setGetLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setInputValue([latitude, longitude]);
    });
  };

  const [showPopup, setShowPopup] = useState(false);
  const [location, setLocation] = useState(null);
  const handleGetLocation = () => {
    if (permissionState === "denied") {
      toast.error(`Location permission is denied, Allow sites to see your location.`, toastErrorMessageStyle());
    } else {
      if (component.action === "select") {
        if (!location) {
          navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ lat: latitude, lng: longitude });
          });
        }
        let mapRoot = document.getElementById("maproot");
        if (!mapRoot.innerHTML) {
          const script = document.createElement("script");
          script.src = `https://maps.googleapis.com/maps/api/js?key=${"AIzaSyDMWCLgNVsigqHdgwgdV0yurVhy6n5dnb0"}&v=weekly&libraries=places`;
          script.async = true;
          script.defer = true;
          script.onload = () => {
            setShowPopup(true);
          };
          mapRoot.appendChild(script);
        } else {
          setShowPopup(true);
        }
      } else {
        setGetLocation();
      }
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (showPopup && location) {
      let map;
      let marker;

      function initMap() {
        const initialLocation = location;
        map = new window.google.maps.Map(document.getElementById(`${namespace}_ffGeoLocationField_Map`), {
          center: initialLocation,
          zoom: 16
        });
        // Create the search box and link it to the UI element.
        const input = document?.getElementById(`${namespace}_ffGeoLocationField_Map_pac`);

        if (input) {
          const searchBox = new window.google.maps.places.SearchBox(input);
          map.addListener("bounds_changed", () => {
            if (map.getBounds()) {
              searchBox.setBounds(map.getBounds());
            } else {
              return;
            }
          });

          let markers = [];

          searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();

            if (places.length === 0) {
              return;
            }

            markers.forEach((marker) => {
              marker.setMap(null);
            });
            markers = [];

            const bounds = new window.google.maps.LatLngBounds();

            places.forEach((place) => {
              if (!place.geometry || !place.geometry.location) {
                return;
              }

              marker = new window.google.maps.Marker({
                position: place.geometry.location,
                map: map,
                draggable: true
              });

              markers.push(marker);
              map.setCenter(place.geometry.location);

              // Update location state and input values
              const lat = place.geometry.location.lat();
              const lng = place.geometry.location.lng();
              location.lat = lat;
              location.lng = lng;

              marker.addListener("dragend", () => {
                const newLocation = marker.getPosition();
                const newLat = newLocation.lat();
                const newLng = newLocation.lng();
                location.lat = newLat;
                location.lng = newLng;
                setInputValue([newLat, newLng]);
              });

              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            map.fitBounds(bounds);
          });
        }

        marker = new window.google.maps.Marker({
          position: initialLocation,
          map: map,
          draggable: true
        });

        location.lat = initialLocation.lat;
        location.lng = initialLocation.lng;

        marker.addListener("dragend", () => {
          const newLocation = marker.getPosition();
          location.lat = newLocation.lat();
          location.lng = newLocation.lng();
          setInputValue([newLocation.lat(), newLocation.lng()]);
        });

        map.addListener("click", (e) => {
          marker.setPosition(e.latLng);
          location.lat = e.latLng.lat();
          location.lng = e.latLng.lng();
          setInputValue([e.latLng.lat(), e.latLng.lng()]);
        });
      }

      initMap();
    }
    if (!showPopup && location) {
      setInputValue([location.lat, location.lng]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopup, location]);

  useEffect(() => {
    if (component.action === "auto" && !inputValue?.[0]) {
      setGetLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionState]);

  if (component.action === "auto") {
    component.disable = true;
  } else if (component.action === "manual") {
  } else if (component.action === "select") {
    component.disable = true;
  } else {
    component.disable = true;
  }

  return (
    <>
      <div style={{ display: component.background_run ? "none" : "" }} className="geo_location_form_parent_container">
        <div className="geo_location_form_label">
          <FormLabel
            required={component.validate.required || component.validate.mandatory}
            sx={{ color: "#667085", fontSize: "14px" }}
          >
            {component.label}
          </FormLabel>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <TextField
            id={component.id}
            type="number"
            fullWidth
            disabled={component.disable}
            placeholder="latitude"
            name="0"
            value={inputValue[0] || ""}
            onChange={(e) => setInputDataInState(e.target.value, 0)}
            size="small"
            sx={{
              "& .MuiInputLabel-root": {
                color: "var(--mui-label-color)"
              },
              "& .MuiInputLabel-root.Mui-error": {
                color: "var(--mui-label-error-color)" // color when there's an error
              },
              "& .MuiInputLabel-root.MuiInputLabel-shrink": {
                color: "var(--mui-label-shrink-color)" // color when the label is at the top (shrunk)
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderWidth: "var(--mui-border-width)",
                  borderColor: "var(--mui-border-color)" // default border color
                },
                "&:hover fieldset": {
                  borderWidth: "var(--mui-border-width)",
                  borderColor: "var(--mui-border-color)" // border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderWidth: "var(--mui-border-width)",
                  borderColor: "var(--mui-border-color)" // border color when focused
                },
                "&.Mui-disabled fieldset": {
                  borderWidth: "var(--mui-border-width)",
                  borderColor: "var(--mui-border-color)", // border color when focused
                  backgroundColor: "var(--mui-background-disabled-color)"
                },
                "&.Mui-error fieldset": {
                  borderWidth: "var(--mui-border-width)",
                  borderColor: "var(--mui-border-error-color)" // border color when there's an error
                }
              }
            }}
          />
          <TextField
            id={component.id}
            type="number"
            fullWidth
            disabled={component.disable}
            placeholder="longitude"
            name="1"
            value={inputValue[1] || ""}
            onChange={(e) => setInputDataInState(e.target.value, 0)}
            size="small"
            sx={{
              "& .MuiInputLabel-root": {
                color: "var(--mui-label-color)"
              },
              "& .MuiInputLabel-root.Mui-error": {
                color: "var(--mui-label-error-color)" // color when there's an error
              },
              "& .MuiInputLabel-root.MuiInputLabel-shrink": {
                color: "var(--mui-label-shrink-color)" // color when the label is at the top (shrunk)
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderWidth: "var(--mui-border-width)",
                  borderColor: "var(--mui-border-color)" // default border color
                },
                "&:hover fieldset": {
                  borderWidth: "var(--mui-border-width)",
                  borderColor: "var(--mui-border-color)" // border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderWidth: "var(--mui-border-width)",
                  borderColor: "var(--mui-border-color)" // border color when focused
                },
                "&.Mui-disabled fieldset": {
                  borderWidth: "var(--mui-border-width)",
                  borderColor: "var(--mui-border-color)", // border color when focused
                  backgroundColor: "var(--mui-background-disabled-color)"
                },
                "&.Mui-error fieldset": {
                  borderWidth: "var(--mui-border-width)",
                  borderColor: "var(--mui-border-error-color)" // border color when there's an error
                }
              }
            }}
          />
          <button className="secondary_default_button" onClick={handleGetLocation}>
            <span className="material-symbols-outlined">my_location</span>
          </button>
        </div>
        {errors.length > 0 && (
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        )}
      </div>
      {showPopup && (
        <div className="ffGeoLocationField_MapPopupOuterBox">
          <div className="ffGeoLocationField_MapPopupBox">
            <div className="popupSection_navbar">
              <span className="popupSection_navbar_display">Location</span>
              <span
                className="material-symbols-outlined popupSection_navbar_closeBTN"
                onClick={() => closePopup(false)}
              >
                close
              </span>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <input
                id={`${namespace}_ffGeoLocationField_Map_pac`}
                className="zino_form_pac_box"
                type="text"
                placeholder="Search Box"
              />
              <div id={`${namespace}_ffGeoLocationField_Map`} style={{ height: "50vh" }}></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FFGeoLocationField;
