import React, { useState, useCallback, useEffect } from "react";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import { getApiCallLocalPath } from "../../utils/apiCallFunction";
import { getDataFromDataObjectByKey } from "../../utils/updateConfigFunction";
import { convertNumbetToDate, convertNumbetToTime } from "../../lib/utils/commonFunction";
import { JobActionRenderer } from "../../lib";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Autocomplete, Stack, TextField } from "@mui/material";

const DropdownNavigate = ({ itemConfig, jobParamsConfig, functionObject }) => {
  const { pagename } = useParams();
  const [outputOption, setOutputOption] = useState();
  const [inputOption, setInputOption] = useState([]);

  const getOptionLabelFun = (option) => {
    if (!option) return;
    let label = [];
    for (let index = 0; index < (itemConfig?.display_fields || []).length; index++) {
      const element = itemConfig.display_fields[index];
      let ans = [];
      getDataFromDataObjectByKey(option, ans, element.id);
      if (["datetime", "date", "time"].includes(element.field_type)) {
        for (let ihai = 0; ihai < ans.length; ihai++) {
          const dateItem = ans[ihai];
          if (element.field_type === "time") {
            ans[ihai] = convertNumbetToTime(dateItem);
          } else {
            ans[ihai] = convertNumbetToDate(dateItem, element.field_type);
          }
        }
      }
      label = [...label, ...ans];
    }
    return label.join(" , ");
  };

  const apiCallAndGetDataDebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const apiCallAndGetData = async (term, search_iId = null) => {
    try {
      let field_Data = {
        element_id: itemConfig.id,
        screen_name: pagename,
        device: "mobile"
      };

      let apiConfig = {
        Params: {
          search_query: {
            search_term: term || "",
            search_fields: [],
            facet_by: [],
            page: 1,
            per_page: 10,
            instance_id: search_iId || null
          },
          table_id: itemConfig.data_id
        },
        data: field_Data,
        function_name: "perform_search"
      };

      let token_data = jobParamsConfig || {};
      if (Object.keys(token_data).length > 0) {
        apiConfig["params"]["token_data"] = token_data;
      }
      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, apiConfig, {
        headers: getApiCallHeadersData()
      });
      let document = data?.data?.documents || [];
      setInputOption(document);
      return document;
    } catch (error) {
      setInputOption([]);
      return [];
    }
  };

  const mapLookup = () => {
    if (!inputOption.length > 0) return inputOption;

    const lookupItems = inputOption.map((lookup, index) => {
      lookup["sm_id"] = itemConfig.data_id;
      lookup["instance_id"] = lookup.id;
      const label = getOptionLabelFun(lookup);

      const displayObj = {
        name: label,
        id: lookup.id || index,
        data: lookup
      };

      return displayObj;
    });
    return lookupItems;
  };

  const buttonClassName = () => {
    let class_name = "";
    if (itemConfig.button && itemConfig.button.className) {
      if (typeof itemConfig.button.className === "object") {
        class_name = Object.values(itemConfig.button.className).join(" ");
      } else {
        class_name = itemConfig.button.className;
      }
    }
    return class_name || "";
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiCallAndGetDataOptimizedFun = useCallback(apiCallAndGetDataDebounce(apiCallAndGetData), []);

  useEffect(() => {
    apiCallAndGetDataOptimizedFun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack>
        <Autocomplete
          renderInput={(params) => <TextField {...params} label={itemConfig?.name} />}
          getOptionLabel={(option) => option.name}
          size="small"
          options={mapLookup()}
          onChange={(event, option) => setOutputOption(option)}
        />
      </Stack>
      <JobActionRenderer
        itemConfig={{
          action_end: {
            hide: [],
            refresh: [],
            show: []
          },
          action_start: {
            hide: [],
            refresh: [],
            show: []
          },
          display_logic: {
            logics: []
          },
          icon: itemConfig?.button?.icon || "",
          id: itemConfig.id,
          data_id: itemConfig.data_id,
          job_config_id: "",
          job_id: "6602a533058a42602b7e4f23",
          job_template: "navigate_with_mapped",
          custom_classname: "navigate_dropdown_button",
          className: buttonClassName(),
          name: itemConfig?.button?.name || "",
          params: {
            name: itemConfig?.page,
            navigate_variable_mapping: itemConfig?.mapping || []
          }
        }}
        jobParamsConfig={jobParamsConfig || {}}
        reqData={outputOption || {}}
      />
    </>
  );
};

export default DropdownNavigate;
