import React from "react";
import IconRender from "../../../components/IconRender";
// import { formatTimestamp } from "../../utils/commonFunction";
import { getDataFromDataObjectByKey } from "../../../utils/updateConfigFunction";
// import NumberFormater from "../../components/NumberFormater";
import CardItems from "../../sectionrendererlib/card/CardItems";
import JobActionRenderer from "../../actionlib/jobactionlib/JobActionRenderer";

export const TableBody = ({ tableBody, tableData, borderFlag, jobParamsConfig }) => {
  const findBgColor = (dataIs, colorIfConfig = []) => {
    let colorIf = {};
    for (let index = 0; index < colorIfConfig.length; index++) {
      const elem = colorIfConfig[index];
      colorIf[elem.value.toLowerCase()] = elem.color;
    }
    let bgColor = "";
    if (typeof dataIs[0] === "string" && dataIs[0] !== "-") {
      bgColor = colorIf[dataIs[0].toLowerCase()] || "";
    } else if (typeof dataIs[0] === "number") {
      bgColor = colorIf[dataIs[0]];
    }
    return bgColor;
  };

  return (
    <tbody>
      {tableBody.map((row, index) => {
        return (
          <tr key={index} style={{ borderWidth: borderFlag ? "1px" : "0" }}>
            {row.map((itemIs, index) => {
              let dataIs = [];
              itemIs?.data_key && getDataFromDataObjectByKey(tableData || {}, dataIs, itemIs?.data_key || "");
              if (itemIs.data_type === "text") {
                return (
                  <td
                    key={index}
                    align={"left"}
                    style={{ borderWidth: borderFlag ? "1px" : "0" }}
                    className="tableContent"
                  >
                    {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
                    <span
                      className={itemIs?.show_chips && "tableChip"}
                      style={{ backgroundColor: findBgColor(itemIs.data || dataIs, itemIs.colorIf) }}
                    >
                      {itemIs.data || dataIs.join(", ") || "-"}
                      {itemIs?.button_list?.length > 0 && (
                        <>
                          {itemIs?.button_list?.map((button, buttonIndex) => (
                            <JobActionRenderer
                              key={buttonIndex}
                              itemConfig={button}
                              reqData={tableData}
                              jobParamsConfig={jobParamsConfig}
                            />
                          ))}
                        </>
                      )}
                    </span>
                  </td>
                );
              } else {
                itemIs.field_type = itemIs.data_type;
                return (
                  <td
                    key={index}
                    align={"left"}
                    className="tableContext"
                    style={{ borderWidth: borderFlag ? "1px" : "0" }}
                  >
                    <CardItems colItem={itemIs} dataConfig={tableData} />
                    {itemIs?.button_list?.length > 0 && (
                      <>
                        {itemIs?.button_list?.map((button, buttonIndex) => (
                          <JobActionRenderer
                            key={buttonIndex}
                            itemConfig={button}
                            reqData={tableData}
                            jobParamsConfig={jobParamsConfig}
                          />
                        ))}
                      </>
                    )}
                  </td>
                );
              }
              //   if (itemIs.data_type === "number") {
              //     return (
              //       <td
              //         key={index}
              //         align={"left"}
              //         className="tableContext"
              //         style={{ borderWidth: borderFlag ? "1px" : "0" }}
              //       >
              //         {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
              //         <span className={itemIs?.show_chips && "tableChip"}>
              //           {dataIs?.length > 0
              //             ? dataIs?.map((num, numindex) => (
              //                 <NumberFormater
              //                   key={numindex}
              //                   number={num !== "-" ? num : 0}
              //                   type={itemIs?.format}
              //                   curr_icon={itemIs?.currency_code}
              //                 />
              //               ))
              //             : "-"}
              //         </span>
              //       </td>
              //     );
              //   } else if (itemIs.data_type === "currency") {
              //     const formatValue = (currencyObject) => {
              //       if (typeof currencyObject === "object") {
              //         const formatter = new Intl.NumberFormat(undefined, {
              //           style: "currency",
              //           currency: currencyObject?.currency
              //         });
              //         return formatter.format(currencyObject?.value);
              //       } else {
              //         return "-";
              //       }
              //     };
              //     return (
              //       <td
              //         key={index}
              //         align={"left"}
              //         className="tableContext"
              //         style={{ borderWidth: borderFlag ? "1px" : "0" }}
              //       >
              //         {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
              //         {dataIs?.length > 0
              //           ? dataIs?.map((num, numindex) => (
              //               <React.Fragment key={numindex}>{formatValue(num)}</React.Fragment>
              //             ))
              //           : "-"}
              //       </td>
              //     );
              //   } else if (
              //     itemIs.data_type === "date" ||
              //     itemIs.data_type === "time" ||
              //     itemIs.data_type === "datetime"
              //   ) {
              //     if (dataIs?.length > 0) {
              //       let formattedDate = "";
              //       for (let iIs = 0; iIs < dataIs?.length; iIs++) {
              //         const dateItem = dataIs[iIs];
              //         if (dateItem) {
              //           try {
              //             formattedDate += formatTimestamp(dateItem, itemIs.data_type, itemIs.format || "DD-MM-YYYY");
              //           } catch (error) {
              //             formattedDate += "-";
              //           }
              //         }
              //       }
              //       return (
              //         <td
              //           key={index}
              //           align={"left"}
              //           className="tableContext"
              //           style={{ borderWidth: borderFlag ? "1px" : "0" }}
              //         >
              //           {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
              //           <span className={itemIs?.show_chips && "tableChip"} style={{ backgroundColor: findBgColor() }}>
              //             {formattedDate || "-"}
              //           </span>
              //         </td>
              //       );
              //     } else {
              //       return (
              //         <td
              //           key={index}
              //           align={"left"}
              //           style={{ borderWidth: borderFlag ? "1px" : "0" }}
              //           className="tableContext"
              //         >
              //           {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
              //           {"-"}
              //         </td>
              //       );
              //     }
              //   } else {
              //     return (
              //       <td key={index} style={{ borderWidth: borderFlag ? "1px" : "0" }} className="tableContext">
              //         {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
              //         <span
              //           className={itemIs?.show_chips && "tableChip"}
              //           style={{ background: findBgColor(dataIs, itemIs?.colorif) }}
              //         >
              //           {dataIs.join(", ") || itemIs.data || "-"}
              //         </span>
              //       </td>
              //     );
              //   }
            })}
          </tr>
        );
      })}
    </tbody>
  );
};
