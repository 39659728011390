import React, { useLayoutEffect, useState, useCallback, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { commonFunction } from "../utils/commonFunction";
import { useSelector } from "react-redux";
import axios from "axios";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../../utils/storageFunction";
import { useFormHandlerContext } from "../FormContext";
import { getDataFromDataObjectByKey } from "../../../utils/updateConfigFunction";
import toast from "react-hot-toast";
import HelpTextIcon from "./HelpTextIcon";

const OPERATION = {
  ADD: 0,
  EDIT: 1
};

function FFLookupField({ component, currentConfigKey, namespace }) {
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  let formConfigData = storeData?.[`${namespace}_formConfigData`] || {};

  const { openFillNewLookupForm, formExData, setFormExData, isOpenExForm } = useFormHandlerContext();

  const [outputOption, setOutputOption] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || ""
  );
  const [inputOption, setInputOption] = useState([]);

  const apiCallAndGetDataDebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const apiCallAndGetData = async (term) => {
    try {
      let { data } = await axios.post(
        `${getApiCallLocalPath()}api/v1/dynamic`,
        {
          Params: {
            search_query: {
              search_term: term || "",
              search_fields: [],
              facet_by: [],
              page: 1,
              per_page: 10
            },
            table_id: component.table_id
          },
          data: {
            form_id: formConfigData.id,
            field_id: component.id,
            activity_id: formConfigData.activity_id,
            entity_id: formConfigData.entity_id,
            sm_id: formConfigData.sm_id,
            instance_id: formConfigData.instance_id
          },
          function_name: "perform_search"
        },
        { headers: getApiCallHeadersData() }
      );

      let document = data?.data?.documents || [];
      if (component.prefill_filtered_data && document.length === 1) {
        validate(document[0]);
      }

      setInputOption(document);
      return document;
    } catch (error) {
      toast.error(`${component.label} data not available`, toastErrorMessageStyle());
      setInputOption([]);
      return [];
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiCallAndGetDataOptimizedFun = useCallback(apiCallAndGetDataDebounce(apiCallAndGetData), []);

  useEffect(() => {
    if (component.show_default_option) {
      apiCallAndGetDataOptimizedFun();
      apiCallAndGetDataDebounce(apiCallAndGetData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setOutputOption(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    setOutputOption(value);
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component, true);
    // setInputOption([]);
  };

  const getOptionLabelFun = (option) => {
    if (!option) return;
    let label = [];
    for (let index = 0; index < component.display_fields.length; index++) {
      const element = component.display_fields[index];
      let ans = [];
      getDataFromDataObjectByKey(option, ans, element.id);
      label = [...label, ...ans];
    }
    return label.join(" , ");
  };

  const openFormHandler = (operation) => {
    if (operation === OPERATION.ADD) {
      openFillNewLookupForm({
        table_id: component.table_id,
        function_name: "register_entity",
        field_id: currentConfigKey
      });
    } else if (operation === OPERATION.EDIT) {
      openFillNewLookupForm({
        table_id: component.table_id,
        function_name: "edit_entity_fe",
        field_id: currentConfigKey,
        instance_id: outputOption?.["id"],
        lookup: outputOption
      });
    }
  };

  const callFun = async (lookupData) => {
    await commonFunction.callChackFiledFunction(namespace, lookupData, currentConfigKey, component, true);
  };

  useEffect(() => {
    if (isOpenExForm || !formExData || formExData["field_id"] !== currentConfigKey) return;
    let lookupData = formExData["form_response"];
    if (lookupData) {
      callFun(lookupData);
      setInputOption([lookupData]);
      setOutputOption(lookupData);
    }
    setFormExData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenExForm]);

  return (
    <div className="form_field_outer_box">
      <Stack spacing={2} width={"100%"}>
        <Autocomplete
          id={component.id || component._id}
          options={inputOption}
          getOptionLabel={getOptionLabelFun}
          value={outputOption || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label={component.label}
              placeholder={getOptionLabelFun(outputOption || null)}
              onChange={(e) => apiCallAndGetDataOptimizedFun(e.target.value)}
              required={component.validate.required || component.validate.mandatory}
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    {outputOption === "" && component?.help_text && <HelpTextIcon help_text={component?.help_text} />}
                  </>
                )
              }}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "var(--mui-label-color)"
                },
                "& .MuiInputLabel-root.Mui-error": {
                  color: "var(--mui-label-error-color)" // color when there's an error
                },
                "& .MuiInputLabel-root.MuiInputLabel-shrink": {
                  color: "var(--mui-label-shrink-color)" // color when the label is at the top (shrunk)
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)" // default border color
                  },
                  "&:hover fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)" // border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)" // border color when focused
                  },
                  "&.Mui-disabled fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)", // border color when focused
                    backgroundColor: "var(--mui-background-disabled-color)"
                  },
                  "&.Mui-error fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-error-color)" // border color when there's an error
                  }
                }
              }}
            />
          )}
          onChange={(e, value) => {
            validate(value);
          }}
          disabled={component.disable}
          ListboxProps={{ sx: { fontSize: "14px" } }}
        />
      </Stack>

      <div
        className="form_field_outer_box"
        style={{
          width: "100%",
          alignItems: "center",
          margin: "8px 0 0 0",
          flexDirection: "row",
          gap: "12px",
          justifyContent: "flex-end"
        }}
      >
        {component.allow_add_entity && (
          <span style={{ fontSize: "14px" }}>
            Not Found?{" "}
            <span
              style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
              onClick={() => openFormHandler(OPERATION.ADD)}
            >
              Add New
            </span>
          </span>
        )}

        {component.allow_edit_entity && outputOption?.["id"] && (
          <span style={{ fontSize: "14px" }}>
            <span
              style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
              onClick={() => openFormHandler(OPERATION.EDIT)}
            >
              Edit
            </span>
          </span>
        )}
      </div>

      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id || component._id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default FFLookupField;
